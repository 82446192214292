import { Injectable } from "@angular/core";
import { ICanvasPoint } from "../lines";
import { IText } from "../texts";

@Injectable({
	providedIn: 'root'
})
export class TextDrawingService {

	public readonly innerBoundDraggingPx: number = 5;
	public redoTexts: IText[] = [];

	public addRedoText(text: IText): void {
		this.redoTexts.push(text);
	}

	public getLastRedoText(): IText {
		return this.redoTexts.pop()!;
	}

	public drawText(colour: string, text: IText, context: CanvasRenderingContext2D): void {
		context.font = `${text.fontSize}px Helvetica`;
		context.fillStyle = colour;
		context.fillText(text.value, text.x, text.y);
		text.width = context.measureText(text.value).width;
	}

	public insideText(text: IText, point: ICanvasPoint): boolean {

		if (!text || !point) {
			return false;
		}

		return (point.x >= text.x && point.x <= text.x + text.width && point.y >= text.y - text.fontSize && point.y <= text.y);
	}

	public keepOnCanvas(text: IText, ctx: CanvasRenderingContext2D): void {
		text.x < this.innerBoundDraggingPx && (text.x = this.innerBoundDraggingPx);
		text.y < this.innerBoundDraggingPx && (text.y = this.innerBoundDraggingPx);
		text.x >= ctx.canvas.width && (text.x = ctx.canvas.width - this.innerBoundDraggingPx);
		text.y >= ctx.canvas.height && (text.y = ctx.canvas.height - this.innerBoundDraggingPx);
	}
}
