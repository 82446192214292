import { Injectable } from "@angular/core";
import { _3DShapeAnswerDto } from "../../../model/answer/3d-shape/3d-shape-answer-dto";
import { ICanvasPoint, ICircle, ILine, LineType } from "../lines";

@Injectable({
	providedIn: 'root'
})
export class LineDrawingService {
	public redoLines: ILine[] = [];
	public readonly circleRadii: number = 5;
	public dx: number = 0;
	public dy: number = 0;
	public reSizeHandles: ICircle[] = [];

	public addRedoLine(line: ILine): void {
		this.redoLines.push(line);
	}

	public getLastRedoLine(): ILine {
		return this.redoLines.pop()!;
	}

	public drawDefaultLines(answer: _3DShapeAnswerDto, context: CanvasRenderingContext2D): void {
		if (answer.defaultLines) {
			answer.defaultLines.forEach((line) => {
				this.setLineType(line.lineType, context);
				context.beginPath();
				context.moveTo(line.startX, line.startY);
				context.lineTo(line.endX, line.endY);
				context.strokeStyle = "#000";
				context.lineWidth = 1;
				context.stroke();
				context.closePath();
			});
		}
	}

	public drawLine(lineToDraw: ILine, context: CanvasRenderingContext2D, drawResizeHandles: boolean = false): void {
		this.setLineType(lineToDraw.lineType, context);
		context.beginPath();
		context.moveTo(lineToDraw.startX, lineToDraw.startY);
		context.lineTo(lineToDraw.endX, lineToDraw.endY);
		context.strokeStyle = "#000";
		context.lineWidth = 1;
		context.stroke();
		context.closePath();

		if (drawResizeHandles) {
			this.drawResizeHandles(context, lineToDraw);
		}
	}

	public drawLineToBeRemoved(lineToDraw: ILine, context: CanvasRenderingContext2D): void {
		this.setLineType(lineToDraw.lineType, context);
		context.beginPath();
		context.moveTo(lineToDraw.startX, lineToDraw.startY);
		context.lineTo(lineToDraw.endX, lineToDraw.endY);
		context.strokeStyle = "#f00";
		context.lineWidth = 1;
		context.stroke();
		context.closePath();
	}

	public checkCirclesHit(mousePos: ICanvasPoint): boolean {

		return this.reSizeHandles.some((circle) => this.intersectsCircle(mousePos.x, mousePos.y, circle.startX, circle.startY, circle.radius));
	}

	public intersectsCircle(x: number, y: number, cx: number, cy: number, r: number): boolean {
		this.dx = x - cx;
		this.dy = y - cy;

		return Math.pow(this.dx, 2) + Math.pow(this.dy, 2) <= Math.pow(r, 2);
	}

	public linePointsEqual(line: ILine): boolean {

		if (line) {
			return line.startX === line.endX && line.startY === line.endY;
		}

		return false;
	}

	public calculateDistance(mousePoint: ICanvasPoint, dot: ICanvasPoint): number {
		return Math.hypot(mousePoint.x - dot.x, mousePoint.y - dot.y);
	}

	public scaleCanvasCoords(screenX: number, screenY: number, hasPageZoomed: boolean, context: CanvasRenderingContext2D): ICanvasPoint {
		if (window.devicePixelRatio === 1 && !hasPageZoomed) {
			// If page is not zoomed, don't work out the scale as it will be 1.
			// However if the page has been scaled and is subsequently scaled back to 1, do the calculations
			// as there are some weird interactions when entering a question zoomed in and zooming out to 100%.
			return { x: screenX, y: screenY };
		}

		hasPageZoomed = true;
		const matrix = context.getTransform();
		const imatrix = matrix.invertSelf();
		const x = screenX * imatrix.a + screenY * imatrix.c + imatrix.e;
		const y = screenX * imatrix.b + screenY * imatrix.d + imatrix.f;

		return { x, y };
	}

	private setLineType(lineType: LineType, context: CanvasRenderingContext2D): void {
		if (lineType === LineType.dashed) {
			context.setLineDash([5, 3]);
		} else {
			context.setLineDash([]);
		}
	}

	private drawResizeHandles(context: CanvasRenderingContext2D, line: ILine): void {
		this.reSizeHandles = [];

		context.beginPath();
		context.moveTo(line.startX, line.startY);
		context.arc(line.startX, line.startY, this.circleRadii, 0, (2 * Math.PI), false);

		this.reSizeHandles.push({
			startX: line.startX,
			startY: line.startY,
			radius: this.circleRadii
		} as ICircle);

		context.moveTo(line.endX, line.endY);
		context.arc(line.endX, line.endY, this.circleRadii, 0, (2 * Math.PI), false);

		this.reSizeHandles.push({
			startX: line.endX,
			startY: line.endY,
			radius: this.circleRadii
		} as ICircle);

		context.stroke();
		context.closePath();
	}
}
