import { Component, ViewChild, ElementRef, AfterViewInit, Input, OnInit, HostListener, OnChanges, AfterViewChecked, OnDestroy } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Subscription } from 'rxjs';
import { ProtractorService } from '../../service/protractor.service';

@Component({
  selector: 'app-protractor',
  templateUrl: './protractor.component.html',
  styleUrls: ['./protractor.component.scss']
})
export class ProtractorComponent implements AfterViewInit, OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @ViewChild('protractorCanvas', { static: false }) public protractorCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('rotateHandle', { static: false }) public rotateHandle!: ElementRef<HTMLDivElement>;
  @ViewChild('rotateContainer', { static: false }) public rotateContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('dragContainer', { static: false }) public dragContainer!: ElementRef<HTMLDivElement>;

  public sizeRatio: number = 1;

  @Input() public helix: boolean = true;
  @Input() public dottedAngleLines: boolean = true;
  get radius(): number { return 256 * this.sizeRatio; }
  @Input() public bigNumbers: boolean = true;

  public context!: CanvasRenderingContext2D;
  public height?: number;
  public width?: number;
  public dragContainerWidth?: number;
  public dragContainerHeight?: number;
  public radiansArc?: number;
  public angleStart: number = 0;
  public angleEnd?: number;
  public bigAngles: number[] = [90];
  public bigAnglesHelix: number[] = [90, 180, 270, 360];
  public dottedAngles: number[] = [45, 135, 225, 315];
  public thickAngleLinesIncrement: number = 45;
  public majorAngleIncrements: number = 10;
  public angleIncrements: number = 5;
  public minorAngleIncrements: number = 1;
  public canvasPadding: number = 1;
  public littleRadius?: number;
  public topInnerLineRadius?: number;
  public dashedLineRadius?: number;
  public littleDashedLineRadius?: number;
  public bottomAngleLineRadius?: number;
  public bottomMinorAngleLineRadius?: number;
  public innerTextRadius?: number;
  public outerTextRadius?: number;
  public bigTextRadius?: number;
  public redraw: boolean = false;

  // Dragging
  public dragging: boolean = false;
  public baseOriginX: number = 0;
  public baseOriginY: number = 0;
  public originX: number = 0;
  public originY: number = 0;
  public h_x: number = 0;
  public h_y: number = 0;
  public lastAngle: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(private protractorService: ProtractorService) { }

  ngOnInit(): void {
    this.init();

    this.subscriptions.push(this.protractorService.protractorSizeRatioChange$.subscribe((newSizeRatio: number) => {
      this.sizeRatio = newSizeRatio;
      if (!this.context) { return; }
      this.init();
      this.redraw = true;
    }))
  }

  ngAfterViewInit(): void {
    this.drawProtractor();
  }

  ngOnChanges(): void {
    if (!this.context) { return; }
    this.init();
    this.redraw = true;
  }

  ngAfterViewChecked(): void {
    if (!this.context || !this.redraw) { return; }
    this.redraw = false;
    this.resetCanvas();
    this.drawProtractor();
  }

  public ngOnDestroy(): void {
    this.subscriptions.map(x => x && x.unsubscribe());
  }

  private init(): void {
    this.width = this.radius * (window.devicePixelRatio * 2) + (this.canvasPadding * 5);
    this.dragContainerWidth = this.width / window.devicePixelRatio;
    this.dragContainerHeight = this.dragContainerWidth / 1.9;
    this.height = this.radius * (window.devicePixelRatio) * 1.05;

    if (this.helix) {
      this.dragContainerHeight = Number(this.dragContainerWidth);
      this.height = Number(this.width);
    }

    this.angleEnd = !this.helix ? 180 : 360;
    this.radiansArc = !this.helix ? Math.PI : Math.PI * 2;

    this.littleRadius = this.radius * 0.125;
    this.topInnerLineRadius = this.radius * 0.78125;
    this.dashedLineRadius = this.radius * 0.859375;
    this.littleDashedLineRadius = this.radius * 0.05859375;
    this.bottomAngleLineRadius = this.radius * 0.8984375;
    this.bottomMinorAngleLineRadius = this.radius * 0.9375;
    this.innerTextRadius = this.radius * 0.80859375;
    this.outerTextRadius = this.radius * 0.84765625;
    this.bigTextRadius = this.radius * 0.8203125;
  }

  private resetCanvas(): void {
    this.context.setTransform(1, 0, 0, 1, 0, 0);
    this.context.clearRect(0, 0, this.protractorCanvas.nativeElement.width, this.protractorCanvas.nativeElement.height);
    this.context.resetTransform();

    this.protractorCanvas.nativeElement.style.width = `${this.width}px`;
    this.protractorCanvas.nativeElement.style.height = `${this.helix ? (this.height) : ((this.height!) / 2)}px`;
    this.context.scale(1, 1);

    this.context.closePath();
  }

  private drawProtractor(): void {
    const containerRect = this.protractorCanvas.nativeElement.getBoundingClientRect();
    this.baseOriginX = containerRect.left + (containerRect.width / 2);
    this.baseOriginY = containerRect.top + (containerRect.height);
    const dragContainerRect = this.dragContainer.nativeElement.getBoundingClientRect();
    this.rotateHandle.nativeElement.style.transform = `translate(-30px, -${(dragContainerRect.height / 2) + 20}px`;

    const onMouseDown = this.mouseDown;
    const dpr = (window.devicePixelRatio || 1);
    this.protractorCanvas.nativeElement.style.width = `${this.width! / dpr}px`;
    this.protractorCanvas.nativeElement.style.height = `${this.height! / dpr}px`;
    this.rotateHandle.nativeElement.onmousedown = e => { onMouseDown(this, e); };

    this.context = this.protractorCanvas.nativeElement.getContext('2d')!;
    this.context.scale(dpr, dpr);

    this.context.beginPath();
    // Draw top arc
    this.context.arc(this.radius + this.canvasPadding,
      this.radius + this.canvasPadding,
      this.radius, this.radiansArc!, 0, false);
    this.context.stroke();

    // Draw small arc
    this.context.beginPath();
    this.context.arc(this.radius + this.canvasPadding,
      this.radius + this.canvasPadding,
      this.littleRadius!, this.radiansArc!, 0, false);
    this.context.stroke();

    // Draw 0 degree baseline
    this.context.beginPath();
    this.context.moveTo(this.canvasPadding + this.topInnerLineRadius!,
      this.radius + this.canvasPadding);
    this.context.lineTo(this.canvasPadding + this.topInnerLineRadius! * 2 + this.canvasPadding,
      this.radius + this.canvasPadding);
    this.context.stroke();

    this.context.beginPath();

    this.context.moveTo(this.canvasPadding + this.radius,
      this.canvasPadding + this.radius - this.littleRadius!);
    this.context.lineTo(this.canvasPadding + this.radius,
      this.canvasPadding + this.radius + (!this.helix ? 0 : this.littleRadius!));

    this.context.stroke();

    for (let angle: number = this.angleStart; angle < this.angleEnd! + 1; ++angle) {
      this.context.beginPath();
      switch (true) {
        case this.bigNumbers && !this.helix && this.bigAngles.indexOf(angle) !== -1:
          this.drawBigAngleLine(angle);
          break;
        case this.bigNumbers && this.helix && this.bigAnglesHelix.indexOf(angle) !== -1:
          this.drawBigAngleLine(angle);
          break;
        case this.dottedAngleLines && this.dottedAngles.indexOf(angle) !== -1:
          this.drawDashedAngleLine(angle);
          break;
        case angle % this.majorAngleIncrements === 0:
          this.drawMajorAngleLine(angle);
          break;
        case angle % this.angleIncrements === 0:
          this.drawAngleLine(angle, angle % this.thickAngleLinesIncrement === 0);
          break;
        case angle % this.minorAngleIncrements === 0:
          this.drawMinorAngleLine(angle);
          break;
      }
    }
  }

  private getPoint(x: number, y: number, radius: number, angle: number): number[] {
    return [x - Math.cos(angle) * radius, y - Math.sin(angle) * radius];
  }

  private degreesToRadians(degrees: number): number {
    return (Math.PI / 180) * degrees;
  }

  private radiansToDegrees(radians: number): number {
    return (180 / Math.PI) * radians;
  }

  private getScaledTextSize(textSize: number): number {
    return textSize * (this.radius / 256);
  }

  private drawBigAngleLine(angle: number): void {
    const [littlex, littley] = this.getPoint(this.littleRadius! + this.canvasPadding,
      this.littleRadius! + this.canvasPadding, this.littleRadius!, this.degreesToRadians(angle));

    const [bigx, bigy] = this.getPoint(this.topInnerLineRadius! + this.canvasPadding,
      this.topInnerLineRadius! + this.canvasPadding, this.topInnerLineRadius!, this.degreesToRadians(angle));

    // Move down to the bottom center of the protractor
    this.context.moveTo(littlex + this.radius - (this.littleRadius!), this.radius + littley - this.littleRadius!);
    this.context.lineTo(bigx + this.radius - (this.topInnerLineRadius!), bigy + this.radius - this.topInnerLineRadius!);
    this.context.stroke();

    const [textx, texty] = this.getPoint(this.bigTextRadius! + this.canvasPadding,
      this.bigTextRadius! + this.canvasPadding, this.bigTextRadius!, this.degreesToRadians(angle));

    this.context.beginPath();
    this.context.save();

    const fontArgs = this.context.font.split(' ');
    const newSize = `${this.getScaledTextSize(20)}px`;
    this.context.font = newSize + ' ' + fontArgs[fontArgs.length - 1];

    this.context.translate(textx + this.radius - this.bigTextRadius!, texty + this.radius - this.bigTextRadius!);
    this.context.rotate(this.degreesToRadians(angle + 270));
    this.context.textAlign = 'center';
    this.context.fillText(String(angle), 0, 0);

    this.context.restore();

    this.drawAngleLine(angle, true);
  }

  private drawDashedAngleLine(angle: number): void {
    this.context.beginPath();
    this.context.setLineDash([5, 3]);

    const [littleDashedx, littleDashedy] = this.getPoint(this.littleRadius! + this.canvasPadding,
      this.littleRadius! + this.canvasPadding, this.littleRadius!, this.degreesToRadians(angle));

    const [bigDashedx, bigDashedy] = this.getPoint(this.dashedLineRadius! + this.canvasPadding,
      this.dashedLineRadius! + this.canvasPadding, this.dashedLineRadius!, this.degreesToRadians(angle));

    // Move down to the bottom center of the protractor
    this.context.moveTo(littleDashedx + this.radius - (this.littleRadius!), this.radius + littleDashedy - this.littleRadius!);
    this.context.lineTo(bigDashedx + this.radius - (this.dashedLineRadius!), bigDashedy + this.radius - this.dashedLineRadius!);
    this.context.stroke();

    this.context.setLineDash([]);

    this.context.beginPath();

    const [smallX, smallY] = this.getPoint(this.littleDashedLineRadius! + this.canvasPadding,
      this.littleDashedLineRadius! + this.canvasPadding, this.littleDashedLineRadius!, this.degreesToRadians(angle));

    this.context.moveTo(this.canvasPadding + this.radius, this.canvasPadding + this.radius);
    this.context.lineTo(smallX + this.radius - (this.littleDashedLineRadius!), smallY + this.radius - (this.littleDashedLineRadius!));

    this.context.stroke();

    this.context.beginPath();
    this.drawAngleLine(angle, true);
  }

  private drawMajorAngleLine(angle: number): void {
    const [littlex, littley] = this.getPoint(this.littleRadius! + this.canvasPadding,
      this.littleRadius! + this.canvasPadding, this.littleRadius!, this.degreesToRadians(angle));

    const [bigx, bigy] = this.getPoint(this.topInnerLineRadius! + this.canvasPadding,
      this.topInnerLineRadius! + this.canvasPadding, this.topInnerLineRadius!, this.degreesToRadians(angle));

    // Move down to the bottom center of the protractor
    this.context.moveTo(littlex + this.radius - (this.littleRadius!), this.radius + littley - this.littleRadius!);
    this.context.lineTo(bigx + this.radius - (this.topInnerLineRadius!), bigy + this.radius - this.topInnerLineRadius!);
    this.context.stroke();

    if (!(this.helix && angle === 0)) {
      this.drawAngleNumbers(angle);
    }
    this.drawAngleLine(angle, angle % this.thickAngleLinesIncrement === 0);
  }

  private drawAngleLine(angle: number, bigLine: boolean): void {
    const [littlex, littley] = this.getPoint(this.bottomAngleLineRadius! + this.canvasPadding,
      this.bottomAngleLineRadius! + this.canvasPadding, this.bottomAngleLineRadius!, this.degreesToRadians(angle));

    const [bigx, bigy] = this.getPoint(this.radius + this.canvasPadding,
      this.radius + this.canvasPadding, this.radius, this.degreesToRadians(angle));

    // Move down to the bottom center of the protractor
    this.context.beginPath();
    if (bigLine) {
      this.context.lineWidth = 2;
    }
    this.context.moveTo(littlex + this.radius - (this.bottomAngleLineRadius!), this.radius + littley - this.bottomAngleLineRadius!);
    this.context.lineTo(bigx, bigy);
    this.context.stroke();
    this.context.lineWidth = 1;
    this.context.beginPath();
  }

  private drawMinorAngleLine(angle: number): void {
    const [littlex, littley] = this.getPoint(this.bottomMinorAngleLineRadius! + this.canvasPadding,
      this.bottomMinorAngleLineRadius! + this.canvasPadding, this.bottomMinorAngleLineRadius!, this.degreesToRadians(angle));

    const [bigx, bigy] = this.getPoint(this.radius + this.canvasPadding,
      this.radius + this.canvasPadding, this.radius, this.degreesToRadians(angle));

    // Move down to the bottom center of the protractor
    this.context.moveTo(littlex + this.radius - (this.bottomMinorAngleLineRadius!), this.radius + littley - this.bottomMinorAngleLineRadius!);
    this.context.lineTo(bigx, bigy);
    this.context.stroke();
  }

  private drawAngleNumbers(angle: number): void {
    const innerNumber: number = this.angleEnd! - angle;
    const outerNumber: number = angle;

    const [innerx, innery] = this.getPoint(this.innerTextRadius! + this.canvasPadding,
      this.innerTextRadius! + this.canvasPadding, this.innerTextRadius!, this.degreesToRadians(angle));

    const [outerx, outery] = this.getPoint(this.outerTextRadius! + this.canvasPadding,
      this.outerTextRadius! + this.canvasPadding, this.outerTextRadius!, this.degreesToRadians(angle));

    this.context.save();

    let fontArgs = this.context.font.split(' ');
    let newSize = `${this.getScaledTextSize(11)}px`;
    this.context.font = newSize + ' ' + fontArgs[fontArgs.length - 1];

    this.context.translate(innerx + this.radius - this.innerTextRadius!, innery + this.radius - this.innerTextRadius!);
    this.context.rotate(this.degreesToRadians(angle + 270));
    this.context.textAlign = 'center';
    this.context.fillText(String(innerNumber), 0, 0);
    this.context.restore();

    this.context.save();

    fontArgs = this.context.font.split(' ');
    newSize = `${this.getScaledTextSize(11)}px`;
    this.context.font = newSize + ' ' + fontArgs[fontArgs.length - 1];

    this.context.translate(outerx + this.radius - this.outerTextRadius!, outery + this.radius - this.outerTextRadius!);
    this.context.rotate(this.degreesToRadians(angle + 270));
    this.context.textAlign = 'center';
    this.context.fillText(String(outerNumber), 0, 0);
    this.context.restore();
  }

  public mouseDown(scope: ProtractorComponent, e: MouseEvent): void {
    scope.h_x = e.pageX;
    scope.h_y = e.pageY;
    e.preventDefault();
    e.stopPropagation();
    scope.dragging = true;

    const containerRect = scope.protractorCanvas.nativeElement.getBoundingClientRect();
    scope.baseOriginX = containerRect.left + (containerRect.width / 2);

    if (!scope.helix) {
      scope.baseOriginY = containerRect.top + (containerRect.height);
    } else {
      scope.baseOriginY = containerRect.top + (containerRect.height / 2);
    }

    scope.originX = Number(scope.baseOriginX);
    scope.originY = Number(scope.baseOriginY);
  }

  @HostListener('document:mousemove', ['$event'])
  public mouseMove(e: MouseEvent): void {
    if (this.dragging) {
      const s_x = e.pageX;
      const s_y = e.pageY;
      if (s_x !== this.originX && s_y !== this.originY) {
        let s_rad = Math.atan2((s_y) - this.originY, (s_x) - this.originX);
        s_rad -= Math.atan2(this.h_y - this.originY, this.h_x - this.originX);
        s_rad += this.lastAngle;

        const degree = (s_rad * (360 / (2 * Math.PI)));
        this.rotateContainer.nativeElement.style.transform = `rotate(${degree}deg)`;

        if (!this.helix) {
          this.rotateContainer.nativeElement.style.transformOrigin = '50% 95%';
        } else {
          this.rotateContainer.nativeElement.style.transformOrigin = '50% 50%';
        }
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  public mouseUp(e: MouseEvent): void {
    if (this.dragging) {
      this.dragging = false;
      const s_x = e.pageX;
      const s_y = e.pageY;

      let s_rad = Math.atan2((s_y) - this.originY, (s_x) - this.originX);
      s_rad -= Math.atan2(this.h_y - this.originY, this.h_x - this.originX);
      s_rad += this.lastAngle;

      this.lastAngle = s_rad;
    }
  }
}
