<div class="scroll-to-fab">
  @if (pageYoffset > 0) {
    <button mat-mini-fab
      matTooltip="Scroll to top"
      class="tbs"
      (click)="scrollToTop()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  }
</div>
