import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-scroll',
	templateUrl: './scroll-to.component.html',
	styleUrls: ['./scroll-to.component.scss']
})
export class ScrollToComponent implements OnInit, OnDestroy {

	public pageYoffset: number = 0;

    public ngOnInit(): void {
		document.getElementById("examBody")!.addEventListener("scroll", e => this.onScroll(e));
	}

	public ngOnDestroy(): void {
		document.getElementById("examBody")!.removeEventListener("scroll", e => this.onScroll(e));
	}

	public onScroll(event: Event) {
		this.pageYoffset = document.getElementById("examBody")!.scrollTop;
	}

	public scrollToTop(): void {
		document.getElementById("examBody")!.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth'
		});
	}
}
