@for (editor of answer.answers; track editor) {
  @switch (editor.typeIdentifier) {
    @case (OpenResponseTypeIdentifier.Simple) {
      <app-simple-response [answerText]="editor.answerText"
        [labelText]="editor.labelText"
        [position]="editor.labelPosition"
        [alignment]="editor.labelAlignment"
        [includeWordCount]="!isMathsExam"
        (answerTextChange)="editor.answerText = $event">
      </app-simple-response>
    }
    @case (OpenResponseTypeIdentifier.Advanced) {
      <app-advanced-response [answerText]="editor.answerText"
        [labelText]="editor.labelText"
        [position]="editor.labelPosition"
        [alignment]="editor.labelAlignment"
        [includeWordCount]="!isMathsExam"
        (answerTextChange)="editor.answerText = $event">
      </app-advanced-response>
    }
  }
}

