<div class="row no-gutters">
  <div class="col-2 offset-2 d-flex align-items-center justify-content-center">
    Progress:
  </div>
  <div class="col-5 d-flex align-items-center">
    <div class="progress-bar-container" fxLayout="row">
      <div class="progress-bar bpg" [style.width.%]="calculateProgress()"></div>
    </div>
  </div>
  @if (complete) {
    <div class="col-2 d-flex align-items-center justify-content-center">
      <b>Complete</b>
    </div>
  }
  @if (!complete) {
    <div class="col-1 d-flex align-items-center justify-content-center">
      <b>{{calculateProgress()}}%</b>
    </div>
  }
</div>
