import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScaleAnswerDto } from '../../model/answer/scale/scale-answer-dto';
import { ExamNavigationService } from '../../service/exam-navigation.service';

@Component({
	selector: 'app-scale',
	templateUrl: './scale.component.html',
	styleUrls: ['./scale.component.scss']
})
export class ScaleComponent implements OnInit {

	@Input() public answer!: ScaleAnswerDto;

	public scaleSavedData: boolean[] = [];

	public get boxes(): number[] {
		return new Array(this.answer.pointCount);
	}

	public get points(): number[] {
		return new Array(this.answer.pointCount - 1);
	}

	constructor() { }

	public ngOnInit(): void {

		if (this.answer.answerJSON) {
			this.scaleSavedData = JSON.parse(this.answer.answerJSON);
		} else {
			this.scaleSavedData = new Array<boolean>(this.answer.pointCount).fill(false);
		}
	}

	public setValue(index: number, value: boolean): void {
		this.scaleSavedData[index] = value;
		this.answer.answerJSON = JSON.stringify(this.scaleSavedData);
	}
}
