<div class="answer-group-container">
	<div class="answer">
		<div class="row col-12">
			<mat-grid-list cols="100" rowHeight="1rem">
				<mat-grid-tile *ngFor="let formItem of answer.formItems; let i = index"
							   [colspan]="formItem.colWidth == 0 ? 100 : formItem.colWidth"
							   [rowspan]="formItem.formBuilderInputTypeNumericalIdentifier == FormBuilderElementInputTypeIdentifier.TextArea ? 8 : 4">
					<div class="col-12 px-2">

						@switch (formItem.formBuilderElementTypeNumericalIdentifier) {
						@case(FormBuilderElementTypeIdentifier.Input){
						<label class="px-1" [innerHtml]="formItem.label" *ngIf="formItem.formBuilderInputTypeNumericalIdentifier != FormBuilderElementInputTypeIdentifier.Checkbox"></label>
						@switch (formItem.formBuilderInputTypeNumericalIdentifier) {
						@case (FormBuilderElementInputTypeIdentifier.Text) {
						<input [(ngModel)]="formItem.userInput" type="text" class="form-control" [placeholder]="formItem.label">
						}
						@case (FormBuilderElementInputTypeIdentifier.Number) {
						<input [(ngModel)]="formItem.userInput" [attr.min]="formItem.min ? formItem.min : null" [attr.max]="formItem.max ? formItem.max : null" type="number" class="form-control" [placeholder]="formItem.label">
						}
						@case (FormBuilderElementInputTypeIdentifier.Date) {
						<input [(ngModel)]="formItem.userInput" type="date" class="form-control" max="9999-12-31" [placeholder]="formItem.label">
						}
						@case (FormBuilderElementInputTypeIdentifier.Checkbox) {
						<div class="form-check">
							<input class="form-check-input" [ngModel]="formItem.userInput === 'True'" (ngModelChange)="onCheckboxChange($event, i)" type="checkbox" value="" [placeholder]="formItem.label">
							<label class="form-check-label" [innerHtml]="formItem.label">
							</label>
						</div>
						}
						@case (FormBuilderElementInputTypeIdentifier.TextArea) {
						<textarea [(ngModel)]="formItem.userInput" type="text" class="form-control no-resize" [placeholder]="formItem.label"></textarea>
						}
						}

						}
						@case(FormBuilderElementTypeIdentifier.Select){

						<label [innerHtml]="formItem.label"></label>
						<select class="form-select" [(ngModel)]="formItem.userInput">
							<option value=""></option>
							<option *ngFor="let opt of formItem.selectInputItems"
									[value]="opt.value"
									[innerHtml]="opt.value">
							</option>
						</select>

						}
						@case(FormBuilderElementTypeIdentifier.Image){
						<p>Image</p>
						}
						@case(FormBuilderElementTypeIdentifier.LineBreak){
						<br />
						}
						@case(FormBuilderElementTypeIdentifier.Heading){
						<h1 [innerHtml]="formItem.label"></h1>
						}
						@case(FormBuilderElementTypeIdentifier.Paragraph){
						<p [innerHtml]="formItem.label"></p>
						}
						}
					</div>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
	</div>
</div>
