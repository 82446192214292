import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamLayoutComponent } from './exam-layout/exam-layout.component';
import { ExamHeaderComponent } from './exam-layout/header/exam-header.component';
import { ExamFooterComponent } from './exam-layout/footer/exam-footer.component';
import { ExamTimerComponent } from './exam-layout/header/exam-timer/exam-timer.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../../material/material.module';
import { ToolModule } from '../../tool/tool.module';
import { ProgressBarComponent } from './exam-layout/progress-bar/progress-bar.component';
import { MockExamFooterComponent } from './exam-layout/mock-footer/exam-footer.component';
import { ScrollToComponent } from './exam-layout/scroll-to/scroll-to.component';
import { FormsModule } from '@angular/forms';
import { MockProgressBarComponent } from './exam-layout/mock-progress-bar/progress-bar.component';
import { MockExamHeaderComponent } from './exam-layout/mock-header/exam-header.component';


@NgModule({
	declarations: [
		ExamLayoutComponent,
		ExamHeaderComponent,
		ExamFooterComponent,
		ExamTimerComponent,
		ProgressBarComponent,
		MockExamFooterComponent,
		ScrollToComponent,
		MockProgressBarComponent,
		MockExamHeaderComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		NgbPopoverModule,
		ToolModule,
		FormsModule
	],
	exports: [
		ExamLayoutComponent,
		ExamHeaderComponent,
		ExamFooterComponent,
		ExamTimerComponent,
		NgbPopoverModule,
		ProgressBarComponent,
		MockExamFooterComponent,
		ScrollToComponent,
		MockProgressBarComponent,
		MockExamHeaderComponent
	]
})
export class LayoutModule { }
