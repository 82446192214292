import { Component, EventEmitter, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormAnswerDTO } from '../../model/answer/form/form-answer-dto';
import { FormAnswerItemDTO } from '../../model/answer/form/form-answer-item-dto';
import { FormBuilderElementTypeIdentifier } from '../../model/answer/form/form-builder-element-type-identifier';
import { FormBuilderElementInputTypeIdentifier } from '../../model/answer/form/form-builder-element-input-type-identifier';
import { formatDate } from '@angular/common';

@Component({
	selector: 'app-form-item',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	@Input() public answer!: FormAnswerDTO;


	public FormBuilderElementTypeIdentifier = FormBuilderElementTypeIdentifier;
	public FormBuilderElementInputTypeIdentifier = FormBuilderElementInputTypeIdentifier;


	ngOnInit(): void {
		this.answer.formItems = this.setupFormItems(this.answer!.formItems!)
	}

	public setupFormItems(formItems: FormAnswerItemDTO[]): FormAnswerItemDTO[] {

		formItems.sort((a: FormAnswerItemDTO, b: FormAnswerItemDTO) => a.index - b.index);

		formItems.forEach(item => {
			if ((item.userInput == null || item.userInput == "") && (item.defaultValue || item.checkedByDefault)) {
				switch (item.formBuilderInputTypeNumericalIdentifier) {
					case FormBuilderElementInputTypeIdentifier.Date:
						var stringValue = formatDate(item.defaultValue!, "yyyy-MM-dd", "en-GB")
						item.userInput = stringValue
						break;
					case FormBuilderElementInputTypeIdentifier.Checkbox:   
						item.userInput = item.checkedByDefault == true ? "True" : "";
						break;
					default:
						item.userInput = item.defaultValue!;
						break;
				}
			}
		})

		return formItems;
	}

	onCheckboxChange(checked: boolean, index: number) {
		this.answer.formItems[index].userInput = checked ? 'True' : 'False';
	}
}
