<table #table>
  <tr>
    @for (col of colsArray; track col) {
      <th scope="col" class="table-cell"></th>
    }
  </tr>

  @for (row of outerRowsArray; track row) {
    <tr>
      @for (row of colsArray; track row) {
        <td scope="row" class="table-cell"></td>
      }
    </tr>
  }
</table>

