import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentUploadAnswerDto } from '../../model/answer/document-upload/document-upload-answer-dto';
import { FileUploadConfig } from '@highfieldabc/angular-highfield-cdn/lib/shared/models/file-upload-config';

@Component({
	selector: 'app-document-upload',
	templateUrl: './document-upload.component.html',
	styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent{

	@Input() public answer!: DocumentUploadAnswerDto;
	@Input() public isPreview: Boolean = false;
	@Input() public isMock: Boolean = false;

	public fileUploadConfig: FileUploadConfig = {
		showCreateFolderButton: false,
		showDownloadButton: false,
		showAddFileButton: true,
		showDeleteButton: true,
		showActionColumn: false,
		showTypeColumn: false,
		showUploadedByColumn: false,
		showUploadedDateColumn: false,
		showVersionColumn: false,
		showBreadCrumbs: false
	}

	public shouldUseProxy: boolean = true;
	constructor(@Inject('CoreExamCDNCategoryId') public coreExamCDNCategoryId: string, @Inject('CoreExamsUserId') public coreExamsUserId: string) { }

	public markAsAnswered(): void {
		this.answer.hasInteracted = true;
	}
}
