import { LabelAlignmentIdentifier } from "../../model/answer/open-response/label-alignment.enum";
import { LabelPositionIdentifier } from "../../model/answer/open-response/label-position.enum";

export class LabellingService {

	public static getDirection(position: LabelPositionIdentifier): object {
		let direction: string;

		switch (position) {

			case LabelPositionIdentifier.Above:
			case LabelPositionIdentifier.Below:
				direction = "column";
				break;
			case LabelPositionIdentifier.Left:
			case LabelPositionIdentifier.Right:
				direction = "row";
				break;
		}

		return {
			"flex-direction": direction,
		};
	}

	public static getAlignment(alignment: LabelAlignmentIdentifier): { alignItem: string, textAlign: string } {

		let alignItem!: string;
		let textAlign!: string;

		switch (alignment) {
			case LabelAlignmentIdentifier.Bottom:
				alignItem = "end";
				break;
			case LabelAlignmentIdentifier.Center:
				textAlign = "center";
				break;
			case LabelAlignmentIdentifier.Left:
				textAlign = "left";
				break;
			case LabelAlignmentIdentifier.Middle:
				alignItem = "center";
				break;
			case LabelAlignmentIdentifier.Right:
				textAlign = "right";
				break;
			case LabelAlignmentIdentifier.Top:
				alignItem = "start";
				break;
			default:
				textAlign = "";
				alignItem = "";
		}
		return { alignItem, textAlign };
	}

	public static getOrderForLabel(position: LabelPositionIdentifier): string {

		switch (position) {
			case LabelPositionIdentifier.Above:
				return "1";
			case LabelPositionIdentifier.Below:
				return "2";
			case LabelPositionIdentifier.Left:
				return "1";
			case LabelPositionIdentifier.Right:
				return "2";
		}
	}

	public static getOrderForTextArea(position: LabelPositionIdentifier): object {

		let flexOrder: string;

		switch (position) {
			case LabelPositionIdentifier.Above:
				flexOrder = "2";
				break;
			case LabelPositionIdentifier.Below:
				flexOrder = "1";
				break;
			case LabelPositionIdentifier.Left:
				flexOrder = "2";
				break;
			case LabelPositionIdentifier.Right:
				flexOrder = "1";
				break;
		}

		return {
			"order": flexOrder,
		};
	}
}
