@if (labelText) {
  <div class="outer-wrapper h-100">
    <div class="wrapper" spellcheck="false" [ngStyle]="getFlexDirection()">
      <label class="or-question-text" [ngStyle]="getLabelStyles()"><b [innerHTML]="labelText"></b></label>
      <div class="or" [ngStyle]="getTextAreaStyles()">
        <quill-editor class="editor" [ngModel]="answerText" (ngModelChange)="onAnswerTextChange($event)" preserveWhitespace="true"></quill-editor>
      </div>
    </div>
    @if (includeWordCount) {
      <div class="word-count">{{getAnswerLength(answerText)}} words</div>
    }
  </div>
}
@if (!labelText) {
  <div class="h-100 outer-wrapper">
    <div class="wrapper" spellcheck="false" [ngStyle]="{ 'height': editorHeight }">
      <quill-editor class="editor or" [ngModel]="answerText" (ngModelChange)="onAnswerTextChange($event)" preserveWhitespace="true"></quill-editor>
    </div>
    @if (includeWordCount) {
      <div class="word-count">{{getAnswerLength(answerText)}} words</div>
    }
  </div>
}

