import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpenResponseAnswerGroupDto } from '../../model/answer/open-response/open-response-group-answer-dto';
import { OpenResponseLengthIdentifier } from '../../model/answer/open-response/open-response-length.enum';
import { OpenResponseTypeIdentifier } from '../../model/answer/open-response/open-response-type.enum';


@Component({
	selector: 'app-open-response',
	templateUrl: './open-response.component.html',
	styleUrls: ['./open-response.component.scss'],
})
export class OpenResponseComponent{

	@Input() public answer!: OpenResponseAnswerGroupDto;

	@Input() public isMathsExam: boolean = false;

	public OpenResponseLengthIdentifier = OpenResponseLengthIdentifier;
	public OpenResponseTypeIdentifier = OpenResponseTypeIdentifier;

}
