export enum Function {
	Zero,
	One,
	Two,
	Three,
	Four,
	Five,
	Six,
	Seven,
	Eight,
	Nine,
	Decimal,
	Equals,
	Add,
	Subtract,
	Multiply,
	Divide,
	Percentage,
	Negate,
	CE,
	C,
	ParenthesisLeft,
	ParenthesisRight,
	Square,
	Cube,
	PowerOf,
	Pi,
	SquareRoot,
	Blank
}

export enum Operation {
	Add = 12,
	Subtract = 13,
	Multiply = 14,
	Divide = 15,
	PowerOf = 24,
	SquareRoot = 26
}

export enum CalculationSide {
	Left,
	Right
}
