
<div cdkDrag
	 [style.width.px]="dragContainerWidth"
	 [style.height.px]="dragContainerHeight"
	 #dragContainer
	 class="drag-container">
	<div [style.width.px]="dragContainerWidth"
		 [style.height.px]="dragContainerHeight"
		 #rotateContainer>
		<canvas #protractorCanvas
				[width]="width"
				[height]="height">
		</canvas>
		<span class="material-icons rotate-handle" #rotateHandle>refresh</span>
	</div>
</div>
