<div class="container-fluid header row bs fs">
  <div class="col-2 row-item justify-content-start">
    @if (enableTimer) {
    <app-exam-timer class="timer" (timerRedirectAction)="timerRedirect($event)"></app-exam-timer>
    }
  </div>
  <div class="col-2 row-item justify-content-center">
    @if (isExam && learnerName) {
    <span>Candidate:&nbsp;<b>{{learnerName}}</b></span>
    }
  </div>
  <div class="col-4 row-item justify-content-center">
    @if (text) {
    <span>@if (isExam) {
      <span>Subject:&nbsp;</span>
      }<b>{{text}}</b></span>
    }
  </div>
  <div class="col-3 row-item justify-content-end">
    @if (showProgressBar) {
    <app-mock-progress-bar></app-mock-progress-bar>
    }
  </div>
  <div class="col-1 row-item justify-content-end">
    <button mat-icon-button class="tbs" (click)="showThemesModal()"><mat-icon>settings</mat-icon></button>
  </div>
</div>