<div class='document-container'>
	<div *ngIf="!isPreview && !isMock">
		<p>Upload your documents below:</p>
		<div class="row">
			<div class="col-12 cdn-wrapper">
				<highfield-uploads-shared-uploaded-files [linkId]="answer.id" [categoryId]="coreExamCDNCategoryId" [useProxy]="shouldUseProxy" [proxyUserId]="coreExamsUserId" [configuration]="fileUploadConfig" (nodeCountChanged)="markAsAnswered()"></highfield-uploads-shared-uploaded-files>
			</div>
		</div>
	</div>
	<div *ngIf="isPreview">
		<div class="row">
			<p>Question preview does not support document upload answers</p>
		</div>
	</div>
	<div *ngIf="isMock">
		<div class="row">
			<p>Mock exams do not support document upload answers</p>
		</div>
	</div>
</div>
