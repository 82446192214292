<div class="wrapper d-flex flex-column">
  <div class="item-control">
    <mat-button-toggle-group name="drawingModeSelector" aria-label="Select drawing mode" (change)="onModeChange($event)" value="0">
      <mat-button-toggle value="0" aria-label="Line draw mode">
        <mat-icon>draw</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="1" aria-label="Text mode">
        <mat-icon>text_fields</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  @if (mode == CanvasMode.LineDrawing) {
    <h6 class="line-style-title">Line Style:</h6>
    <div class="item-control">
      <mat-select class="line-selector" [value]="selectedLineType" (selectionChange)="onLineTypeChange($event)">
        <mat-option [value]="0">Solid</mat-option>
        <mat-option [value]="1">Dashed</mat-option>
      </mat-select>
    </div>
    <div class="item-control magnetic-lines-toggle">
      <mat-slide-toggle [checked]="magneticPointsModeEnabled"
        [color]="matSelectColor"
        (change)="onMagnetPointsChange($event)">
        Magnetic Lines
      </mat-slide-toggle>
      <mat-icon class="help" matTooltip="When magnetic lines are enabled, both ends of your line will snap to the nearest dot on the grid when you begin drawing your next line">help_outline</mat-icon>
    </div>
    <div class="item-control grid-remove-toggle">
      <mat-slide-toggle [checked]="gridEnabled"
        [color]="matSelectColor"
        (change)="onGridEnabledChange($event)">
        Enable Grid Lines
      </mat-slide-toggle>
      <mat-icon class="help" matTooltip="When enabled, the graph will have a grid background instead of the default dots.">help_outline</mat-icon>
    </div>
  }

  @if (mode == CanvasMode.Text) {
    <h6 class="line-style-title">Font size:</h6>
    <div class="item-control">
      <mat-select class="line-selector" [value]="selectedFontSize" (selectionChange)="onFontSizeChange($event)">
        <mat-option [value]="20">Small</mat-option>
        <mat-option [value]="32">Medium</mat-option>
        <mat-option [value]="40">Large</mat-option>
      </mat-select>
    </div>
    <div class="item-control">
      <mat-form-field>
        <input matInput type="text" spellcheck="false" autocomplete="off" [disabled]="disableText" [(ngModel)]="selectedText" class="mat-">
      </mat-form-field>
    </div>
    <div class="item-control">
      <button mat-raised-button
        color="primary"
        matTooltip="Add text to canvas"
        matTooltipPosition="right"
        (click)="onTextAdd()"
        type="button">
        <mat-icon>add</mat-icon>
        Add text
      </button>
    </div>
  }

  <div class="item-control grid-toggle">
    <mat-slide-toggle [checked]="gridRemoveEnabled"
      [color]="matSelectColor"
      (change)="onGridRemoveEnabledChanged($event)">
      Enable Remove
    </mat-slide-toggle>
    <mat-icon class="help" matTooltip="When enabled, clicking on a line/text will remove it from the grid.">help_outline</mat-icon>
  </div>


  <div class="item-control">
    <button mat-raised-button
      color="primary"
      matTooltip="Undo last action, can be repeated"
      matTooltipPosition="right"
      (click)="onUndo()"
      type="button">
      <mat-icon>undo</mat-icon>&nbsp;Undo
    </button>
  </div>
  <div class="item-control">
    <button mat-raised-button
      color="primary"
      matTooltip="Redo last undone action, can be repeated"
      matTooltipPosition="right"
      (click)="onRedo()"
      type="button">
      <mat-icon>redo</mat-icon>&nbsp;Redo
    </button>
  </div>
  <div class="item-control">
    <button mat-raised-button
      color="primary"
      matTooltip="Clears canvas completely"
      matTooltipPosition="right"
      (click)="onClear()"
      type="button">
      <mat-icon>clear</mat-icon>&nbsp;Clear
    </button>
  </div>
</div>


