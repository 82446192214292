import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { LabelAlignmentIdentifier } from '../../../model/answer/open-response/label-alignment.enum';
import { LabelPositionIdentifier } from '../../../model/answer/open-response/label-position.enum';
import { LabellingService } from '../open-response-labelling';

@Component({
	selector: 'app-advanced-response',
	templateUrl: './advanced-response.component.html',
	styleUrls: ['./advanced-response.component.scss'],
})
export class AdvancedResponseComponent implements AfterViewInit {

	@Input() public answerText!: string;
	@Output() public answerTextChange = new EventEmitter<string>();
	@Input() public labelText!: string;
	@Input() public position!: LabelPositionIdentifier;
	@Input() public alignment!: LabelAlignmentIdentifier;
	@Input() public includeWordCount: boolean = false;

	public LabelAlignmentIdentifier = LabelAlignmentIdentifier;

	public editorHeight: string = '100%';

	public ngAfterViewInit(): void {
		setTimeout(() => {
			try {
				const hasTopLabel: boolean = this.labelText && this.position === LabelPositionIdentifier.Above || this.position === LabelPositionIdentifier.Below;
				this.editorHeight = `calc(100% - ${(document.getElementsByClassName('ql-toolbar')[0] as HTMLElement)['offsetHeight'] + (hasTopLabel ? 39 : 0)}px)`;

			} catch { }
		}, 250);
	}

	public getFlexDirection(): object {
		return LabellingService.getDirection(this.position);
	}

	public getLabelStyles(): object {
		const flexOrder = LabellingService.getOrderForLabel(this.position);
		const { alignItem, textAlign } = LabellingService.getAlignment(this.alignment);
		let styles: object = {};

		if (textAlign) {
			styles =  {
				'order': flexOrder,
				'text-align': textAlign
			};
		}

		if (alignItem) {
			styles = {
				'order': flexOrder,
				'display': 'flex',
				'align-items': alignItem
			};
		}

		if (this.position === LabelPositionIdentifier.Below) {
			styles = { ...styles, 'margin-top': '39px' };
		}

		return styles;
	}

	public getTextAreaStyles(): object {
		const textAreaStyles: object = LabellingService.getOrderForTextArea(this.position);

		return {...textAreaStyles, height: this.editorHeight };
	}

	public getAnswerLength(text: string): number {
		if (text) {
			return text.split(/(\s+)/).filter( e => e.trim().length > 0).length;
		}

		return 0;
	}

	public onAnswerTextChange(value: string): void {
		this.answerText = value;
		this.answerTextChange.emit(this.answerText);
	}
}
