<div class='scale-container'>
  <div class="select-container">
    @for (box of boxes; track box; let i = $index) {
      <input [ngModel]="scaleSavedData[i]" (ngModelChange)="setValue(i, $event)" type="checkbox" class="checkbox">
    }
  </div>
  <div class="point-container">
    @for (point of points; track point) {
      <div class="point-box"></div>
    }
  </div>
  <div class="label-container">
    <b class="label">{{ answer.leftText }}</b>
    <b class="label">{{ answer.middleText }}</b>
    <b class="label">{{ answer.rightText }}</b>
  </div>
</div>
