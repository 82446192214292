import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { ThemePalette } from "@angular/material/core";
import { MatSelectChange } from "@angular/material/select";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { LineType } from "../lines";
import { CanvasMode } from "../modes";
import { IText } from "../texts";

@Component({
	selector: 'app-drawing-canvas-controls',
	templateUrl: './drawing-canvas-controls.component.html',
	styleUrls: ['./drawing-canvas-controls.component.scss']
})
export class DrawingCanvasControlsComponent {

	@Input() public mode!: CanvasMode;
	@Input() public disableText!: boolean;
	@Output() public undo: EventEmitter<void> = new EventEmitter<void>();
	@Output() public redo: EventEmitter<void> = new EventEmitter<void>();
	@Output() public clear: EventEmitter<void> = new EventEmitter<void>();
	@Output() public lineTypeChange: EventEmitter<LineType> = new EventEmitter<LineType>();
	@Output() public magnetPointsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public gridEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public gridRemoveEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public modeChange: EventEmitter<CanvasMode> = new EventEmitter<CanvasMode>();
	@Output() public textAdd: EventEmitter<IText> = new EventEmitter<IText>();

	public CanvasMode = CanvasMode;
	public selectedLineType: number = 0;
	public magneticPointsModeEnabled: boolean = false;
	public gridEnabled: boolean = false;
	public gridRemoveEnabled: boolean = false;
	public matSelectColor: ThemePalette = "primary";
	public selectedText!: string;
	public selectedFontSize: number = 32; //medium
	public readonly canvasCentrePoint: number = 275;

	public onUndo(): void {
		this.undo.emit();
	}

	public onRedo(): void {
		this.redo.emit();
	}

	public onClear(): void {
		this.clear.emit();
	}

	public onModeChange(e: MatButtonToggleChange): void {
		const mode = Number.parseInt(e.value);
		this.modeChange.emit(mode as CanvasMode);
	}

	public onLineTypeChange(e: MatSelectChange): void {
		const lineType = e.value as LineType;
		this.lineTypeChange.emit(lineType);
	}

	public onFontSizeChange(e: MatSelectChange): void {
		this.selectedFontSize = Number.parseInt(e.value);
	}

	public onMagnetPointsChange(e: MatSlideToggleChange): void {
		this.magneticPointsModeEnabled = e.checked;
		this.magnetPointsChange.emit(this.magneticPointsModeEnabled);
	}

	public onGridEnabledChange(e: MatSlideToggleChange): void {
		this.gridEnabled = e.checked;
		this.gridEnabledChange.emit(this.gridEnabled);
	}

	public onGridRemoveEnabledChanged(e: MatSlideToggleChange): void {
		this.gridRemoveEnabled = e.checked;
		this.gridRemoveEnabledChange.emit(this.gridRemoveEnabled);
	}

	public onTextAdd(): void {
		if (this.selectedText) {
			const text: IText = {
				x: this.canvasCentrePoint,
				y: this.canvasCentrePoint,
				width: 0, //needs canvas context to measure
				fontSize: this.selectedFontSize,
				value: this.selectedText
			};

			this.textAdd.emit(text);
			this.selectedText = "";
		}
	}
}
