import { ICanvasSavedData } from "./canvas-saved-data.interface";

export class CanvasDataMapper {

	// Maps old format of array of lines into new type that can also hold text data
	public static mapToCanvasSaveData(obj: any): ICanvasSavedData {
		let result: ICanvasSavedData;

		if (Array.isArray(obj)) {
			result = {};
			result.lines = obj;
			result.texts = [];
		} else {
			result = obj;
			result.lines = result.lines || [];
			result.texts = result.texts || [];
		}

		return result;
	}

}
