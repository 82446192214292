export interface ILine {
	startX: number,
	startY: number,
	endX: number,
	endY: number,
	lineType: LineType
}

export interface ICircle {
	radius: number,
	startX: number,
	startY: number
}

export enum LineType {
	Solid = 0,
	dashed = 1
}

export interface ICanvasPoint {
	x: number,
	y: number
}
