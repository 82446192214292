<div class="container-fluid">
  @if (answer.additionalText) {
    <div class="row">
      <div class="col">
        <p><strong>NOTE:</strong> {{answer.additionalText}}</p>
      </div>
    </div>
  }

  <div class="row">
    <div class="col d-flex justify-content-center">
      <div class="wrapper">
        <canvas #tempCanvas
          id="temp-canvas"
          (mousemove)="onMouseMove($event)"
          (mouseup)="onMouseUp($event)"
          (mousedown)="onMouseDown($event)"
          (mouseleave)="onMouseLeave($event)">
        </canvas>
        <canvas #drawingCanvas id="main-canvas"></canvas>
        <canvas #assetCanvas id="asset-canvas"></canvas>
        <canvas #gridCanvas id="grid-canvas"></canvas>
      </div>
      <app-drawing-canvas-controls [(mode)]="drawingMode"
        [disableText]="disableText"
        (undo)="undoLastAction()"
        (redo)="redoLastAction()"
        (clear)="clear()"
        (textAdd)="drawText($event)"
        (lineTypeChange)="selectedLineType = $event;"
        (magnetPointsChange)="magneticPointsEnabled = $event;"
        (modeChange)="drawingMode = $event;"
        (gridEnabledChange)="toggleGrid()"
        (gridRemoveEnabledChange)="toggleRemove()">
      </app-drawing-canvas-controls>
    </div>
  </div>
</div>
