import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TableAnswerCellDto } from "../../model/answer/table/table-answer-cell-dto";
import { TableAnswerDto } from "../../model/answer/table/table-answer-dto";

@Component({
	selector: "app-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnInit, AfterViewInit {

	@ViewChild('table') public tableEl!: ElementRef<HTMLTableElement>;

	@Input() public answer!: TableAnswerDto;

	public rowsArray: number[] = [];
	public colsArray: number[] = [];
	public outerRowsArray: number[] = [];

	public ngOnInit(): void {
		this.colsArray = this.setUpArray(this.answer.cols);
		this.rowsArray = this.setUpArray(this.answer.rows);
		this.outerRowsArray = this.setUpArray(this.answer.rows - 1);
	}

	public ngAfterViewInit(): void {
		let i = 0;
		for (i = 0; i < this.answer.tableAnswerCells.length; i++) {
			this.setCellInfo(this.answer.tableAnswerCells[i]);
		}
	}

	private getValuesFromId(id: string): [number, number] {

		const dashPos = id.indexOf("-");
		const col = Number.parseInt(id.substring(dashPos, 0));
		const row = Number.parseInt(id.substring(dashPos + 1));

		return [col, row];
	}

	private setUpArray(size: number): number[] {

		const arr: number[] = [];
		let i = 0;

		for (i = 0; i < size; i++) {
			arr.push(0);
		}

		return arr;
	}

	private setCellInfo(data: TableAnswerCellDto): void {
		const cell = this.tableEl.nativeElement.rows[data.row].cells[data.col];
		if (data.isEditable) {
			const input = document.createElement("input");
			input.id = `${data.col}-${data.row}`;
			input.className = "or";
			input.type = "text";

			input.value = this.answer.tableAnswerCells.find(x => x.col === data.col && x.row === data.row)!.text ?? null;
			input.autocomplete = "off";
			input.placeholder = "Enter text here";
			input.style.width = "100%";
			input.oninput = (e) => {

				const el = <HTMLInputElement>e.target;
				const [col, row] = this.getValuesFromId(el.id);

				this.answer.tableAnswerCells.find(x => x.col === col && x.row === row)!.text = el.value;
			};

			cell.appendChild(input);
		}
		else {
			cell.innerText = data.text;
		}
	}
}
