<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="ngx-calculator-container">
        <div class="ngx-calculator-workings-container">
          @for (calculation of calculations; track calculation; let i = $index) {
            <p>
              <b>{{ i + 1 }}.</b>&nbsp;{{ calculation.renderCalculation() }}
            </p>
          }
        </div>
        <div class="ngx-calculator-function-container">
          <div class="ngx-calculator-visor or" [innerHTML]="visorText"></div>
          <div class="ngx-calculator-function-row">
            <button class="ngx-calculator-function-button hidden-button"></button>
            <button class="ngx-calculator-function-button hidden-button"></button>
            <button class="ngx-calculator-function-button hidden-button"></button>
            <button id="ce" (click)="input(function.CE)" class="ngx-calculator-function-button">CE</button>
            <button id="c" (click)="input(function.C)" class="ngx-calculator-function-button">C</button>
          </div>
          <div class="ngx-calculator-function-row">
            <button id="negate" (click)="input(function.Negate)" class="ngx-calculator-function-button"><sup>+</sup>/&nbsp;<sub>-</sub></button>
            <button id="seven" (click)="input(function.Seven)" class="ngx-calculator-function-button calculator-number">7</button>
            <button id="eight" (click)="input(function.Eight)" class="ngx-calculator-function-button calculator-number">8</button>
            <button id="nine" (click)="input(function.Nine)" class="ngx-calculator-function-button calculator-number">9</button>
            <button id="divide" (click)="input(function.Divide)" class="ngx-calculator-function-button">÷</button>
          </div>
          <div class="ngx-calculator-function-row">
            <button id="percentage" (click)="input(function.Percentage)" class="ngx-calculator-function-button">%</button>
            <button id="four" (click)="input(function.Four)" class="ngx-calculator-function-button calculator-number">4</button>
            <button id="five" (click)="input(function.Five)" class="ngx-calculator-function-button calculator-number">5</button>
            <button id="six" (click)="input(function.Six)" class="ngx-calculator-function-button calculator-number">6</button>
            <button id="multiply" (click)="input(function.Multiply)" class="ngx-calculator-function-button">X</button>
          </div>
          <div class="ngx-calculator-function-row">
            <button id="squareRoot" (click)="input(function.SquareRoot)" class="ngx-calculator-function-button">√</button>
            <button id="one" (click)="input(function.One)" class="ngx-calculator-function-button calculator-number">1</button>
            <button id="two" (click)="input(function.Two)" class="ngx-calculator-function-button calculator-number">2</button>
            <button id="three" (click)="input(function.Three)" class="ngx-calculator-function-button calculator-number">3</button>
            <button id="subtract" (click)="input(function.Subtract)" class="ngx-calculator-function-button">-</button>
          </div>
          <div class="ngx-calculator-function-row">
            <button class="ngx-calculator-function-button hidden-button"></button>
            <button id="zero" (click)="input(function.Zero)" class="ngx-calculator-function-button calculator-number">0</button>
            <button id="decimal" (click)="input(function.Decimal)" class="ngx-calculator-function-button">.</button>
            <button id="equals" (click)="input(function.Equals)" class="ngx-calculator-function-button equals-button">=</button>
            <button id="add" (click)="input(function.Add)" class="ngx-calculator-function-button">+</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
