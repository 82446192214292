import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReorderAnswerDto } from '../../model/answer/reorder/reorder-answer-dto';

@Component({
	selector: 'app-reorder',
	templateUrl: 'reorder.component.html',
	styleUrls: ['reorder.component.scss'],
})
export class ReorderComponent {

	@Input() public answer!: ReorderAnswerDto;

	public drop(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.answer.reorderAnswerRows, event.previousIndex, event.currentIndex);

		this.answer.reorderAnswerRows.forEach((val, i) => {
			val.orderIndex = i;
		});
	}
}
