import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { CalculatorComponent } from './calculator/calculator.component';
import { DrawingCanvasControlsComponent } from './drawing-canvas/controls/drawing-canvas-controls.component';
import { DrawingCanvasComponent } from './drawing-canvas/drawing-canvas.component';
import { ProtractorComponent } from './protractor/protractor.component';
import { ReorderComponent } from './reorder/reorder.component';
import { RulerComponent } from './ruler/ruler.component';
import { TableComponent } from './table/table.component';
import { OpenResponseComponent } from './open-response/open-response.component';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { MathsToolbarComponent } from '../site/exam/toolbars/maths-toolbar/maths-toolbar.component';
import { ScaleComponent } from './scale/scale.component';
import { SimpleResponseComponent } from './open-response/simple/simple-response.component';
import { AdvancedResponseComponent } from './open-response/advanced/advanced-response.component';
import { ChartConfirmComponent } from './charts/chart-selector/chart-confirm/chart-confirm.component';
import { MultiChoiceComponent } from './multi-choice/multi-choice.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { SharedModule as CDNSharedModule } from '@highfieldabc/angular-highfield-cdn';
import { FormComponent } from './form/form.component';


@NgModule({
	declarations: [
		ProtractorComponent,
		RulerComponent,
		DrawingCanvasComponent,
		DrawingCanvasControlsComponent,
		ReorderComponent,
		CalculatorComponent,
		TableComponent,
		OpenResponseComponent,
		MathsToolbarComponent,
		ScaleComponent,
		SimpleResponseComponent,
		AdvancedResponseComponent,
		ChartConfirmComponent,
		MultiChoiceComponent,
		FormComponent,
		DocumentUploadComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		MaterialModule,
		QuillModule.forRoot({
			modules: {
				toolbar: [
					['bold', 'italic', 'underline'],        // toggled buttons
					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
					[{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
					[{ 'direction': 'rtl' }],                         // text direction
					[{ 'align': [] }],
					['clean'],                                         // remove formatting button
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				]
			},
			customOptions: [{
				import: 'attributors/style/size',
				whitelist: ['8px', '10px', '12px', '14px', '16px', '18px', '20px']
			}]
		}),
		CDNSharedModule
	],
	exports: [
		ProtractorComponent,
		RulerComponent,
		DrawingCanvasComponent,
		DrawingCanvasControlsComponent,
		CalculatorComponent,
		TableComponent,
		ReorderComponent,
		OpenResponseComponent,
		MathsToolbarComponent,
		ScaleComponent,
		SimpleResponseComponent,
		AdvancedResponseComponent,
		ChartConfirmComponent,
		MultiChoiceComponent,
		DocumentUploadComponent,
		CDNSharedModule,
		FormComponent
	]
})
export class ToolModule { }
