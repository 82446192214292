import { CalculationSide, Operation } from './calculator-operations.enum';

export class Calculation {
	public left?: number | Calculation | null = null;
	public right?: number | Calculation | null = null;
	public operation?: Operation | null = null;
	public oneSidedOperation?: boolean;
	public parenthesis?: boolean;
	public result?: number;
	public pendingDecimal?: boolean;
	public trailingZeros: number = 0;
	public valueBeforeTrail?: number | null;

	public get operationApplied() { return !!this.operation; }
	public get isComplete() { return this.left != null && this.operationApplied && this.right != null; }
	public get notSquareRoot() { return this.operation !== Operation.SquareRoot; }
	public get isEmpty() { return !(this.left != null) && !(!!this.operationApplied) && !(this.right != null); }

	constructor(left: number) {
		this.left = left;
	}

	public getCurrentCalculationSide(): CalculationSide {
		// TODO: Refactor and improve the ways in which the calculator detects which side a calculation is currently on and whether that side is a decimal.
		if (this.operationApplied) {
			return CalculationSide.Right;
		} else {
			return CalculationSide.Left;
		}
	}

	public addInputToCalculation(input: string): void {
		this.addInputToCorrectSide(input, this.getCurrentCalculationSide());
	}

	public calculateResult(): number {
		const left = Number(this.left);
		const right = Number(this.right);
		let result: number = 0;
		switch (this.operation) {
			case Operation.Add:
				result = left + right;
				break;
			case Operation.Subtract:
				result = left - right;
				break;
			case Operation.Multiply:
				result = left * right;
				break;
			case Operation.Divide:
				if (right === 0) {
					result = 0;
				} else {
					result = left / right;
				}
				break;
			case Operation.PowerOf:
				result = Math.pow(left, right);
				break;
			case Operation.SquareRoot:
				result = Math.sqrt(left);
		}

		this.result = Number(result.toFixed(9));
		this.trailingZeros = 0;
		this.valueBeforeTrail = null;
		return this.result;
	}

	public renderCalculation(): string {
		const left: string = String(this.left);
		const right: string = this.right != null ? String(this.right) : '';
		const operation: string = this.renderOperation();
		const result = String(this.result);
		return `${left} ${operation}${` ${right}`} = ${result}`;
	}

	public renderPartialCalculation(): string {
		const left: string|null = this.left ? String(this.left) : null;
		const operation: string = this.renderOperation();
		return `${left ? left : ''}${operation ? ` ${operation}` : ''}`;
	}

	public CE(): void {
		if (this.operation) {
			// we use this.operation to check whether we're on the right side of the calculation.
			// If so, clear the right side, else clear the left.
			this.right = null;
		} else {
			this.left = null;
		}
		this.trailingZeros = 0;
		this.pendingDecimal = false;
		this.valueBeforeTrail = null;
	}

	public C(): void {
		this.left = null;
		this.operation = null;
		this.right = null;
		this.trailingZeros = 0;
		this.pendingDecimal = false;
		this.valueBeforeTrail = null;
	}

	public negateCurrentValue(): number {
		if (this.operationApplied) {
			return this.right = -this.right!;
		} else {
			return this.left = -this.left!;
		}
	}

	public convertToPercentage(): number {
		if (!this.operationApplied) {
			return this.left = (Number(this.left) / 100);
		} else {
			if (this.operation == Operation.Add || this.operation == Operation.Subtract) {
				return this.right ?
					this.right = ((Number(this.left) / 100) * Number(this.right)) :
					this.right = ((Number(this.left) / 100) * Number(this.left));
			} else {
				return this.right = Number(this.right) / 100;
			}
		}
	}

	public renderOperation(): string {
		switch (this.operation) {
			case Operation.Add:
				return '+';
			case Operation.Subtract:
				return '-';
			case Operation.Multiply:
				return 'x';
			case Operation.Divide:
				return '/';
			case Operation.PowerOf:
				return '^';
			case Operation.SquareRoot:
				return '√';
				default:
					return "";
		}
	}

	private addInputToCorrectSide(input: string, side: CalculationSide): void {
		switch (input) {
			case '0' : {
				if (!this.trailingZeros && (side === CalculationSide.Left && this.left && this.left.toString().includes('.') || side === CalculationSide.Right && this.right && this.right.toString().includes('.'))) {
					// the number is already a decimal (e.g. n.123)
					this.trailingZeros++;
					if (side === CalculationSide.Left) {
						this.valueBeforeTrail = Number(this.left) ?? 0;
						const zeros = '0'.repeat(this.trailingZeros);
						const rightOfDecimal = this.left?.toString().split('.')[1];
						const decimalsLength = rightOfDecimal ? rightOfDecimal.length : 0;
						this.left = Number(Number(`${this.valueBeforeTrail}${zeros}`).toFixed(decimalsLength + this.trailingZeros));
					} else {
						this.valueBeforeTrail = Number(this.right) ?? 0;
						const zeros = '0'.repeat(this.trailingZeros);
						const rightOfDecimal = this.right?.toString().split('.')[1];
						const decimalsLength = rightOfDecimal ? rightOfDecimal.length : 0;
						this.right = Number(Number(`${this.valueBeforeTrail}${zeros}`).toFixed(decimalsLength + this.trailingZeros));
					}
					this.pendingDecimal = false;
					break;

				} else if (this.pendingDecimal || this.trailingZeros) {
					// If the last input was either a decimal (n.), a trailing 0 after a decimal (n.000)
					this.trailingZeros++;
					if (side === CalculationSide.Left) {
						const currentValue = this.left ? String(this.left) : 0;
						const zeros = '0'.repeat(this.trailingZeros);
						const shouldInsertDecimal = (this.pendingDecimal || (!this.left?.toString().includes('.') && zeros !== ''));
						this.left = Number(Number(`${currentValue}${shouldInsertDecimal ? '.' : ''}${zeros}${input}`).toFixed(this.trailingZeros));
					} else {
						const currentValue = this.right ? String(this.right) : 0;
						const zeros = '0'.repeat(this.trailingZeros);
						const shouldInsertDecimal = (this.pendingDecimal || (!this.right?.toString().includes('.') && zeros !== ''));
						this.right = Number(Number(`${currentValue}${shouldInsertDecimal ? '.' : ''}${zeros}${input}`).toFixed(this.trailingZeros));
					}
					this.pendingDecimal = false;
					break;
				}
				else { 
					if (side === CalculationSide.Left) {
						const currentValue = this.left ? String(this.left) : 0;
						this.left = Number(Number(`${currentValue}${input}`));
					} else {
						const currentValue = this.right ? String(this.right) : 0;
						this.right = Number(Number(`${currentValue}${input}`));
					}

				}
				break;
			}
			default : {
				if (!this.trailingZeros || this.pendingDecimal) {
					// First number after a DP or following a non-zero decimal number
					if (side === CalculationSide.Left) {
						const currentValue = this.left ? String(this.left) : 0;
						this.left = Number(`${currentValue}${this.pendingDecimal ? '.' : ''}${input}`);
					} else {
						const currentValue = this.right ? String(this.right) : 0;
						this.right = Number(`${currentValue}${this.pendingDecimal ? '.' : ''}${input}`);
					}
				} else {
					// Follows the last decimal number
					const zeros = '0'.repeat(this.trailingZeros);

					if (side === CalculationSide.Left) {
						const currentValue = this.left ? String(this.left) : 0;
						if (this.valueBeforeTrail) {
							// If the last decimal number was a 0, use the valueBeforeTrail variable instead of currentValue
							this.left = Number(`${this.valueBeforeTrail}${zeros}${input}`);
						} else {
							this.left = Number(`${currentValue}.${zeros}${input}`);
						}
					} else {
						const currentValue = this.right ? String(this.right) : 0;
						if (this.valueBeforeTrail) {
							this.right = Number(`${this.valueBeforeTrail}${zeros}${input}`);
						} else {
							this.right = Number(`${currentValue}.${zeros}${input}`);
						}
					}

					this.trailingZeros = 0;
					this.valueBeforeTrail = null;
				}
				this.pendingDecimal = false;
			}
		}
	}
}
