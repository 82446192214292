import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output, ViewChild } from "@angular/core";
import { _3DShapeAnswerDto } from "../../model/answer/3d-shape/3d-shape-answer-dto";
import { CanvasDataMapper } from "../../model/answer/3d-shape/canvas-data-mapper";
import { ICanvasSavedData } from "../../model/answer/3d-shape/canvas-saved-data.interface";
import { CanvasAction } from "./canvas-action";
import { ICanvasPoint, ILine, LineType } from "./lines";
import { CanvasMode } from "./modes";
import { LineDrawingService } from "./services/line-drawing.service";
import { TextDrawingService } from "./services/text-drawing.service";
import { IText } from "./texts";

@Component({
	selector: 'app-drawing-canvas',
	templateUrl: './drawing-canvas.component.html',
	styleUrls: ['./drawing-canvas.component.scss']
})
export class DrawingCanvasComponent implements AfterViewInit {
	@ViewChild('drawingCanvas') public canvasEl!: ElementRef<HTMLCanvasElement>;
	@ViewChild('tempCanvas') public tempCanvasEl!: ElementRef<HTMLCanvasElement>;
	@ViewChild('assetCanvas') public assetCanvasEl!: ElementRef<HTMLCanvasElement>;
	@ViewChild('gridCanvas') public gridCanvasEl!: ElementRef<HTMLCanvasElement>;
	@Input() public answer: _3DShapeAnswerDto = new _3DShapeAnswerDto();

	public canvasSavedData: ICanvasSavedData = { lines: [], texts: [] };
	public drawingMode: CanvasMode = CanvasMode.LineDrawing;
	public context!: CanvasRenderingContext2D;
	public tempContext!: CanvasRenderingContext2D;
	public assetContext!: CanvasRenderingContext2D;
	public gridContext!: CanvasRenderingContext2D ;
	public startX: number = 0;
	public startY: number = 0;
	public isDown: boolean = false;
	public dots: ICanvasPoint[] = [];
	public selectedLineType: LineType = LineType.Solid;
	public magneticPointsEnabled: boolean = false;
	public distances: number[] = [];
	public pointPositionIndex: number = 0;
	public lineToCommit?: ILine;
	public textToCommit?: IText;
	public circleIntersected: boolean = false;
	public lineIntersected: boolean = false;
	public closestStart?: ICanvasPoint;
	public closestEnd?: ICanvasPoint;
	public currentTextIndex: number = -1;
	public hasPageZoomed?: boolean; // Used to fix issues for 3D canvas becoming uncalibrated when page is zoomed.
	public disableText: boolean = false;
	public gridEnabled: boolean = false;
	public gridRemove: boolean = false;
	public canvasActions: CanvasAction[] = [];

	constructor(@Inject('CDNUrl') private cdnBaseUrl: string,
		private lineDrawingService: LineDrawingService,
		private textDrawingService: TextDrawingService) { }

	public ngAfterViewInit(): void {
		this.initCanvases();

		if (this.answer.answerJSON) {
			this.canvasSavedData = CanvasDataMapper.mapToCanvasSaveData(JSON.parse(this.answer.answerJSON));
			this.canvasSavedData.lines = this.canvasSavedData?.lines?.filter(x => x);
			this.loadSavedData();
		}

		this.lineDrawingService.drawDefaultLines(this.answer, this.context);
		this.drawAsset();
	}

	public onMouseDown(e: MouseEvent): void {
		let mousePos = this.getMousePosition(e);
		mousePos = this.lineDrawingService.scaleCanvasCoords(mousePos.x, mousePos.y, this.hasPageZoomed!, this.tempContext);

		this.startX = mousePos.x;
		this.startY = mousePos.y;

		this.isDown = true;

		if (this.gridRemove) {
			this.performElementRemove(mousePos);
		}
		else {
			switch (this.drawingMode) {
				case CanvasMode.LineDrawing: {
					this.handleMouseDownLineDrawing(mousePos);
					break;
				}
				case CanvasMode.Text: {
					this.handleMouseDownText(mousePos);
					break;
				}
			}
		}
	}

	public onMouseUp(e: MouseEvent): void {
		this.isDown = false;
		this.circleIntersected = false;
		this.currentTextIndex = -1;
	}

	public onMouseMove(e: MouseEvent): void {
		let mousePos = this.getMousePosition(e);
		mousePos = this.lineDrawingService.scaleCanvasCoords(mousePos.x, mousePos.y, this.hasPageZoomed!, this.tempContext);
		if (!this.gridRemove) {
			switch (this.drawingMode) {
				case CanvasMode.LineDrawing: {
					this.handleMouseMoveLineDrawing(e, mousePos);
					break;
				}
				case CanvasMode.Text: {
					this.handleMouseMoveText(e, mousePos);
					break;
				}
			}
		} else {
			this.drawRedCollidingRemoveLines(mousePos);
		}
	}

	public onMouseLeave(e: MouseEvent): void {
		e.preventDefault();
		this.isDown = false;

		if (this.lineToCommit) {
			this.commitLine();
		}

		if (this.textToCommit) {
			this.commitText();
		}

		this.lineDrawingService.reSizeHandles = [];
		this.clearCanvas(this.tempContext);
		this.currentTextIndex = -1;
	}

	@HostListener('window:resize')
	public onResize(): void {
		this.initCanvases();
		if (this.canvasSavedData) {
			this.loadSavedData();
		}
		this.drawAsset();
	}

	public undoLastAction(): void {

		if (this.canvasActions.length > 0) {

			this.clearCanvas(this.context);
			this.drawDottedBackground(this.context);

			const lastAction = this.canvasActions.pop();

			switch (lastAction) {
				case CanvasAction.Line: {
					this.undoLastLine();
					break;
				}
				case CanvasAction.Text: {
					this.undoLastText();
					break;
				}
			}

			this.loadSavedData();
		}

		this.saveAnswer();
	}

	public redoLastAction(): void {

		const lastAction = this.canvasActions.pop();

		switch (lastAction) {
			case CanvasAction.Line: {
				this.redoLastLine();
				break;
			}
			case CanvasAction.Text: {
				this.redoLastText();
				break;
			}
		}

		this.saveAnswer();
	}

	public clear(): void {
		this.clearCanvas(this.context);
		this.drawDottedBackground(this.context);
		this.clearCanvas(this.tempContext);
		this.lineDrawingService.drawDefaultLines(this.answer, this.context);
		this.drawAsset();
		this.canvasSavedData.lines = [];
		this.canvasSavedData.texts = [];
		this.lineDrawingService.redoLines = [];
		this.lineToCommit = undefined;
		this.lineDrawingService.reSizeHandles = [];
		this.saveAnswer();
	}

	public drawText(text: IText): void {

		this.disableText = true;

		if (!this.textToCommit) {
			this.textDrawingService.drawText("#08f", text, this.tempContext);
			this.textToCommit = text;
		}

		this.disableText = false;
	}

	private clearCanvas(context: CanvasRenderingContext2D): void {
		context.clearRect(0, 0, context.canvas.width / devicePixelRatio, context.canvas.height / devicePixelRatio);
	}

	private commitLine(): void {
		if (this.lineToCommit && !this.circleIntersected) {
			if (!(this.lineToCommit === this.canvasSavedData.lines![this.canvasSavedData.lines!.length - 1])) {
				if (this.magneticPointsEnabled) {
					this.lineToCommit = this.getMagneticValuesForLine(this.lineToCommit);
				}
				this.lineDrawingService.drawLine(this.lineToCommit, this.context);
				this.canvasSavedData.lines?.push(this.lineToCommit);
				this.lineDrawingService.reSizeHandles = [];
			}
		}

		this.canvasActions.push(CanvasAction.Line);

		this.saveAnswer();
	}

	private getMagneticValuesForLine(line: ILine): ILine {
		this.closestStart = this.getClosestDotToMouse({ x: line.startX, y: line.startY } as ICanvasPoint);
		this.closestEnd = this.getClosestDotToMouse({ x: line.endX, y: line.endY } as ICanvasPoint);

		return {
			startX: this.closestStart.x,
			startY: this.closestStart.y,
			endX: this.closestEnd.x,
			endY: this.closestEnd.y,
			lineType: this.selectedLineType ?? LineType.Solid
		} as ILine;
	}

	private loadSavedData(): void {
		this.canvasSavedData.lines?.forEach((line) => {
			this.lineDrawingService.drawLine(line, this.context);
		});

		this.lineDrawingService.drawDefaultLines(this.answer, this.context);
		this.drawAsset();

		this.startX = 0;
		this.startY = 0;

		this.canvasSavedData.texts?.forEach(txt => this.textDrawingService.drawText("black", txt, this.context));
	}

	private getMousePosition(e: MouseEvent): ICanvasPoint {
		var rect = this.canvasEl.nativeElement.getBoundingClientRect(), // abs. size of element
			scaleX = this.canvasEl.nativeElement.width / rect.width, // relationship bitmap vs. element for X
			scaleY = this.canvasEl.nativeElement.height / rect.height; // relationship bitmap vs. element for Y

		return {
			x: (e.clientX - rect.left) * scaleX,
			y: (e.clientY - rect.top) * scaleY
		};
	}

	private drawDottedBackground(context: CanvasRenderingContext2D): void {
		const rectangleSize = 2;

		const cellWidth = 37;
		const cellHeight = 37;

		let calcX: number = 0;
		let calcY: number = 0;

		for (let x = 20; x < this.canvasEl.nativeElement.clientWidth; x += cellWidth) {
			for (let y = 20; y < this.canvasEl.nativeElement.clientHeight; y += cellHeight) {
				context.fillStyle = '#000000';

				calcX = x - rectangleSize / 2;
				calcY = y - rectangleSize / 2;

				context.fillRect(calcX, calcY, rectangleSize, rectangleSize);

				const dot: ICanvasPoint = {
					x: calcX,
					y: calcY
				}

				this.dots.push(dot);
			}
		}
	}

	public toggleGrid() {
		this.gridEnabled = !this.gridEnabled;
		if (this.gridEnabled) {
			this.drawLinedBackground();
		} else {
			this.clearCanvas(this.gridContext);
		}
	}

	public toggleRemove() {
		this.gridRemove = !this.gridRemove;
	}

	private drawLinedBackground(): void {
		const context = this.gridContext;

		const cellWidth = 37;
		const cellHeight = 37;
		context.strokeStyle = '#8f8f8f';

		for (let x = 20; x < this.gridCanvasEl.nativeElement.clientWidth; x += cellWidth) {
			context.beginPath();
			context.moveTo(x, 0);
			context.lineTo(x, this.gridCanvasEl.nativeElement.clientHeight);
			context.stroke();
		}

		for (let y = 20; y < this.gridCanvasEl.nativeElement.clientHeight; y += cellHeight) {
			context.beginPath();
			context.moveTo(0, y);
			context.lineTo(this.gridCanvasEl.nativeElement.clientWidth, y);
			context.stroke();
		}
	}

	private getClosestDotToMouse(mousePoint: ICanvasPoint): ICanvasPoint {
		this.distances = [];

		this.dots.forEach((dot) => {
			this.distances.push(this.lineDrawingService.calculateDistance(mousePoint, dot));
		});

		this.pointPositionIndex = this.distances.indexOf(Math.min(...this.distances));

		return this.dots[this.pointPositionIndex];
	}

	private getClosestLinePointToMouse(mousePoint: ICanvasPoint, line: ILine): number {
		this.distances = [];

		const points: ICanvasPoint[] = [
			{
				x: line.startX,
				y: line.startY
			},
			{
				x: line.endX,
				y: line.endY
			}];

		points.forEach((point) => {
			this.distances.push(this.lineDrawingService.calculateDistance(mousePoint, point));
		});

		this.pointPositionIndex = this.distances.indexOf(Math.min(...this.distances));

		return this.pointPositionIndex;
	}

	private initCanvases(): void {
		const scale = window.devicePixelRatio;

		this.initCanvasSizes([this.canvasEl.nativeElement, this.tempCanvasEl.nativeElement, this.assetCanvasEl.nativeElement, this.gridCanvasEl.nativeElement]);

		this.context = this.canvasEl.nativeElement.getContext('2d')!;
		this.context.scale(scale, scale);

		this.tempContext = this.tempCanvasEl.nativeElement.getContext('2d')!;
		this.tempContext.scale(scale, scale);

		this.assetContext = this.assetCanvasEl.nativeElement.getContext('2d')!;
		this.assetContext.scale(scale, scale);

		this.gridContext = this.gridCanvasEl.nativeElement.getContext('2d')!;
		this.gridContext.scale(scale, scale);

		this.drawDottedBackground(this.context);
		if (this.gridEnabled) {
			this.drawLinedBackground();
		}
	}

	private initCanvasSizes(e: HTMLCanvasElement[]) {
		const scale = window.devicePixelRatio;
		const size = 550;

		e.forEach(c => {
			c.style.width = size + "px";
			c.style.height = size + "px";
			c.width = size * scale;
			c.height = size * scale;
		});
	}

	private drawAsset(): void {
		if (this.answer.assetInfo) {
			let assetElement = new Image();
			let asset = this.answer.assetInfo;
			assetElement.src = ((asset.url).includes(this.cdnBaseUrl) ? asset.url : this.cdnBaseUrl + "assets/" + asset.url);
			assetElement.onload = () => {
				this.assetContext.drawImage(
					assetElement,
					asset.startX,
					asset.startY,
					asset.width,
					asset.height
				);
			};
		}
	}

	private saveAnswer(): void {
		if (this.canvasSavedData) {
			this.answer.answerJSON = JSON.stringify(this.canvasSavedData);
		}
	}

	private drawRedCollidingRemoveLines(mousePos: ICanvasPoint): void {
		let intersectingLines = this.getIntersectedLines(mousePos);
		//tempContext used as no other function uses the temp canvas at the time of removing.
		//If that condition changes in the future then a new context / canvas will need to be made for this at a z-index higher than main.
		this.clearCanvas(this.tempContext);
		if (intersectingLines && intersectingLines.length) {
			let lineToBeRemoved = intersectingLines[intersectingLines.length - 1];
			this.lineDrawingService.drawLineToBeRemoved(lineToBeRemoved, this.tempContext);
		}
	}

	private performElementRemove(mousePos: ICanvasPoint): void {
		let intersectedLines = this.getIntersectedLines(mousePos);
		let intersectedText = this.getIntersectedText(mousePos);
		let anyItemsRemoved = false;

		if (!(intersectedLines.length > 0 && intersectedText.length > 0)) {

			if (intersectedLines.length >= 1) {
				let lineToRemove = intersectedLines[intersectedLines.length - 1];

				let index = this.canvasSavedData.lines?.indexOf(lineToRemove);

				this.lineToCommit = undefined;
				this.canvasSavedData.lines?.splice(index!, 1);
				this.canvasActions.push(CanvasAction.Line);
				this.undoLine(lineToRemove);
				anyItemsRemoved = true;
			}

			if (intersectedText.length >= 1) {
				let textToRemove = intersectedText[0];

				let index = this.canvasSavedData.texts?.indexOf(textToRemove);

				this.textToCommit = undefined;
				this.canvasSavedData.texts?.splice(index!, 1);
				this.canvasActions.push(CanvasAction.Text);
				this.undoText(textToRemove);
				anyItemsRemoved = true;
			}
		}


		if (anyItemsRemoved) {
			this.clearCanvas(this.context);
			this.clearCanvas(this.tempContext);
			this.drawDottedBackground(this.context);
			this.loadSavedData();
			this.saveAnswer();
		}
	}

	public getIntersectedLines(mousePos: ICanvasPoint): ILine[] {
		let intersectedLines: ILine[] = [];
		this.canvasSavedData.lines?.forEach(line => {
			if (this.intersectsLine(mousePos.x, mousePos.y, line.startX, line.startY, line.endX, line.endY)) {
				intersectedLines.push(line);
			}
		})
		return intersectedLines;
	}

	public getIntersectedText(mousePos: ICanvasPoint): IText[] {
		let intersectedText: IText[] = [];
		this.canvasSavedData.texts?.forEach(text => {
			if (this.intersectsText(mousePos.x, mousePos.y, text.x, text.y, text.x + text.width, text.y + text.fontSize, text.width)) {
				intersectedText.push(text);
			}
		})
		return intersectedText;

	}

	public intersectsText(mouseX: number, mouseY: number, textX: number, textY: number, textEndX: number, textEndY: number, width: number): boolean {
		return this.isPointOnElement(mouseX, mouseY, textX, textY, textEndX, textEndY, width);
	}

	public intersectsLine(mouseX: number, mouseY: number, lineStartX: number, lineStartY: number, lineEndX: number, lineEndY: number): boolean {
		if (lineStartX < lineEndX) {
			if (mouseX < lineStartX || mouseX > lineEndX) { return false; }
		} else if (lineStartX > lineEndX) {
			if (mouseX < lineEndX || mouseX > lineStartX) { return false; }
		}
		
		return this.isPointOnElement(mouseX, mouseY, lineStartX, lineStartY, lineEndX, lineEndY, 5);
	}

	private isPointOnElement(mouseX: number, mouseY: number, lineStartX: number, lineStartY: number, lineEndX: number, lineEndY: number, width: number): boolean {
		return this.distancePointFromElement(mouseX, mouseY, lineStartX, lineStartY, lineEndX, lineEndY) <= width / 2
	}
	private distancePointFromElement(mouseX: number, mouseY: number, lineStartX: number, lineStartY: number, lineEndX: number, lineEndY: number): number {
		return Math.abs((lineEndX - lineStartX) * (lineStartY - mouseY) - (lineStartX - mouseX) * (lineEndY - lineStartY)) / Math.sqrt((lineEndX - lineStartX) ** 2 + (lineEndY - lineStartY) ** 2)
	}

	private handleMouseDownLineDrawing(mousePos: ICanvasPoint): void {
		this.circleIntersected = this.lineDrawingService.checkCirclesHit(mousePos);

		if (this.circleIntersected && this.lineToCommit) {

			switch (this.getClosestLinePointToMouse(mousePos, this.lineToCommit)) {
				case 0: {

					this.startX = this.lineToCommit.endX;
					this.startY = this.lineToCommit.endY;

					this.lineToCommit.startX = mousePos.x;
					this.lineToCommit.startY = mousePos.y;
					break;
				}
				case 1: {
					this.startX = this.lineToCommit.startX;
					this.startY = this.lineToCommit.startY;

					this.lineToCommit.endX = mousePos.x;
					this.lineToCommit.endY = mousePos.y;
				}
			}
		}

		this.commitLine();
		this.clearCanvas(this.tempContext);
	}

	private handleMouseMoveLineDrawing(e: MouseEvent, mousePos: ICanvasPoint): void {
		this.circleIntersected = this.lineDrawingService.checkCirclesHit(mousePos);

		if (this.circleIntersected) {
			(e.target as HTMLCanvasElement).style.cursor = "grab";

			if (this.isDown) {
				(e.target as HTMLCanvasElement).style.cursor = "grabbing";
			}
		} else {
			(e.target as HTMLCanvasElement).style.cursor = "crosshair";
		}

		if (!this.isDown) {
			return;
		}

		this.lineToCommit = {
			startX: this.startX,
			startY: this.startY,
			endX: mousePos.x,
			endY: mousePos.y,
			lineType: this.selectedLineType
		};

		this.clearCanvas(this.tempContext);
		this.lineDrawingService.drawLine(this.lineToCommit, this.tempContext, true);
		this.circleIntersected = false;
	}

	private commitText(): void {
		this.textDrawingService.drawText("black", this.textToCommit!, this.context);
		this.canvasSavedData.texts?.push(this.textToCommit!);
		this.clearCanvas(this.tempContext);
		this.currentTextIndex = -1;
		this.textToCommit = undefined;
		this.canvasActions.push(CanvasAction.Text);
		this.saveAnswer();
	}

	private handleMouseMoveText(e: MouseEvent, mousePos: ICanvasPoint): void {

		if (this.textDrawingService.insideText(this.textToCommit!, mousePos)) {
			(e.target as HTMLCanvasElement).style.cursor = "move";
			this.currentTextIndex = 1;
		} else {
			(e.target as HTMLCanvasElement).style.cursor = "default";
		}

		if (this.isDown && this.currentTextIndex !== -1) {
			// check where the mouse is on canvas and minus where it was before, getting distance change
			const dx = mousePos.x - this.startX;
			const dy = mousePos.y - this.startY;
			this.startX = mousePos.x;
			this.startY = mousePos.y;

			this.textToCommit!.x += dx;
			this.textToCommit!.y += dy;

			this.textDrawingService.keepOnCanvas(this.textToCommit!, this.tempContext);

			this.clearCanvas(this.tempContext);
			this.textDrawingService.drawText("#08f", this.textToCommit!, this.tempContext);
		}
	}

	private handleMouseDownText(mousePos: ICanvasPoint): void {

		if (this.textDrawingService.insideText(this.textToCommit!, mousePos)) {
			this.currentTextIndex = 1;
		} else {
			this.currentTextIndex = -1;
		}

		if (this.textToCommit && this.currentTextIndex === -1) {
			this.commitText();
		}
	}

	private undoLastText(): void {
		if (this.canvasSavedData.texts!.length > 0) {
			this.textToCommit = undefined;
			this.textDrawingService.addRedoText(this.canvasSavedData.texts!.pop()!);
		}
	}

	private undoLine(lineToRemove: ILine): void {
		this.lineDrawingService.addRedoLine(lineToRemove);
	}
	private undoText(textToRemove: IText): void {
		this.textDrawingService.addRedoText(textToRemove);
	}

	private undoLastLine(): void {
		if (this.canvasSavedData.lines!.length > 0) {
			this.lineToCommit = undefined;
			this.lineDrawingService.addRedoLine(this.canvasSavedData.lines!.pop()!);
		}
	}

	private redoLastLine(): void {
		if (this.lineDrawingService.redoLines.length) {
			const redoLine = this.lineDrawingService.getLastRedoLine();

			if (redoLine) {
				this.canvasSavedData.lines!.push(redoLine);
				this.lineDrawingService.drawLine(redoLine, this.context);
			}
		}
	}

	private redoLastText(): void {
		if (this.textDrawingService.redoTexts.length) {
			const redoText = this.textDrawingService.getLastRedoText();

			if (redoText) {
				this.canvasSavedData.texts!.push(redoText);
				this.textDrawingService.drawText("black", redoText, this.context);
			}
		}
	}
}
