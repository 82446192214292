<div class="answer-group-container">
  @for (answer of answer.answers; track answer) {
    <div (click)="selectAnswer(answer.index)" class="mca answer">
      @if (!answer.selected) {
        <mat-icon>radio_button_unchecked</mat-icon>
      }
      @if (answer.selected) {
        <mat-icon>radio_button_checked</mat-icon>
      }
      &nbsp;&nbsp;
      <span [innerHTML]="answer.answerText"></span>
    </div>
  }
</div>