import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiChoiceAnswerDTO } from '../../model/answer/multi-choice/multi-choice-answer-dto';
import { MultiChoiceGroupAnswerDTO } from '../../model/answer/multi-choice/multi-choice-group-answer-dto';

@Component({
  selector: 'app-multi-choice',
  templateUrl: './multi-choice.component.html',
  styleUrls: ['./multi-choice.component.scss']
})
export class MultiChoiceComponent implements OnInit {

  @Input() public answer!: MultiChoiceGroupAnswerDTO;
  constructor() { }

  ngOnInit(): void {
    this.answer.answers = this.answer.answers.sort((a: MultiChoiceAnswerDTO, b: MultiChoiceAnswerDTO) => a.index - b.index);
  }

  public selectAnswer(index: number): void {
    this.answer.answers.forEach(x => x.selected = false);
    this.answer.answers.find(x => x.index === index)!.selected = true;
  }
}
