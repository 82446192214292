import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges, AfterViewChecked, Input, HostListener } from '@angular/core';
import { RulerLengthType } from './enum/ruler-length-type.enum';
import { RulerRowType } from './enum/ruler-row-type.enum';

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.scss']
})
export class RulerComponent implements AfterViewInit, OnInit, OnChanges, AfterViewChecked {

  @ViewChild('rulerCanvas', { static: false }) public rulerCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('rotateHandle', { static: false }) public rotateHandle!: ElementRef<HTMLDivElement>;
  @ViewChild('rotateContainer', { static: false }) public rotateContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('dragContainer', { static: false }) public dragContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('ppi', { static: false }) public ppiElement!: ElementRef<HTMLDivElement>;

  @Input() public length: number = 12;
  @Input() public lengthType: RulerLengthType = RulerLengthType.Inch;
  @Input() public scaleMeasurementByResolution = true;
  @Input() public topRowUnitType: RulerLengthType = RulerLengthType.Inch;
  @Input() public bottomRowUnitType: RulerLengthType = RulerLengthType.Centimeter;

  public context!: CanvasRenderingContext2D;
  public height?: number;
  public width?: number;
  public drawHeight?: number;
  public drawWidth?: number;
  public dragContainerWidth?: number;
  public dragContainerHeight?: number;
  public canvasPadding: number = 2;
  public rowHeight: number = 50;
  public redraw: boolean = false;
  public monitorPpi: number = 96;
  public defaultPixelsPerInch: number = 96;
  public centimeterToInchRatio: number = 2.54;

  // Dragging
  public dragging: boolean = false;
  public baseOriginX: number = 0;
  public baseOriginY: number = 0;
  public originX: number = 0;
  public originY: number = 0;
  public h_x: number = 0;
  public h_y: number = 0;
  public lastAngle: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.drawRuler();
  }

  ngOnChanges(): void {
    if (!this.context) { return; }
    this.redraw = true;
    this.init();
  }

  ngAfterViewChecked(): void {
    if (!this.context || !this.redraw) { return; }
    this.redraw = false;
    this.resetCanvas();
    this.drawRuler();
  }

  private init(): void {
    this.width = this.getTotalWidth();
    this.height = this.getTotalHeight();
    this.drawWidth = this.width / window.devicePixelRatio;
    this.drawHeight = this.height / window.devicePixelRatio;
    this.dragContainerWidth = Number(this.width / window.devicePixelRatio);
    this.dragContainerHeight = Number(this.height / window.devicePixelRatio);
  }

  private resetCanvas(): void {
    this.context.setTransform(1, 0, 0, 1, 0, 0);
    this.context.clearRect(0, 0, this.rulerCanvas.nativeElement.width, this.rulerCanvas.nativeElement.height);
    this.context.resetTransform();

    this.rulerCanvas.nativeElement.style.width = `${this.width}px`;
    this.rulerCanvas.nativeElement.style.height = `${this.height}px`;
    this.context.scale(1, 1);

    this.context.closePath();
  }

  private drawRuler(): void {
    this.monitorPpi = this.ppiElement.nativeElement.offsetHeight || this.ppiElement.nativeElement.offsetWidth;
    const containerRect = this.dragContainer.nativeElement.getBoundingClientRect();
    this.baseOriginX = containerRect.left + (containerRect.width / 2);
    this.baseOriginY = containerRect.top + (containerRect.height / 2);
    this.rotateHandle.nativeElement.style.transform = `translate(-30px, -${(containerRect.height / 2) + 20}px`;

    const onMouseDown = this.mouseDown;
    const dpr = window.devicePixelRatio || 1;
    this.rulerCanvas.nativeElement.style.width = `${this.width! / dpr}px`;
    this.rulerCanvas.nativeElement.style.height = `${this.height! / dpr}px`;
    this.rotateHandle.nativeElement.onmousedown = e => { onMouseDown(this, e); };

    this.context = this.rulerCanvas.nativeElement.getContext('2d')!;
    this.context.scale(dpr, dpr);

    this.context.beginPath();
    this.context.moveTo(this.canvasPadding, this.canvasPadding);
    this.context.lineTo(this.drawWidth! - this.canvasPadding, this.canvasPadding);
    this.context.stroke();
    this.context.lineTo(this.drawWidth! - this.canvasPadding, this.drawHeight! - this.canvasPadding);
    this.context.stroke();
    this.context.lineTo(this.canvasPadding, this.drawHeight! - this.canvasPadding);
    this.context.stroke();
    this.context.lineTo(this.canvasPadding, this.canvasPadding);
    this.context.stroke();
    this.context.closePath();

    this.context.beginPath();
    this.context.moveTo(this.canvasPadding, (this.canvasPadding + this.drawHeight!) / 2);
    this.context.lineTo(this.drawWidth! - this.canvasPadding, (this.canvasPadding + this.drawHeight!) / 2);
    this.context.stroke();
    this.context.closePath();

    const topRowOuterY: number = this.canvasPadding;
    const topRowInnerY: number = (this.canvasPadding + this.drawHeight!) / 2;

    switch (this.topRowUnitType) {
      case RulerLengthType.Inch:
        this.drawInchRow(topRowOuterY, topRowInnerY, RulerRowType.Top);
        break;
      case RulerLengthType.Centimeter:
        this.drawCentimeterRow(topRowOuterY, topRowInnerY, RulerRowType.Top);
        break;
      default:
        this.drawInchRow(topRowOuterY, topRowInnerY, RulerRowType.Top);
        break;
    }

    const bottomRowInnerY: number = (this.canvasPadding + this.drawHeight!) / 2;
    const bottomRowOuterY: number = (this.drawHeight! - this.canvasPadding);

    switch (this.bottomRowUnitType) {
      case RulerLengthType.Inch:
        this.drawInchRow(bottomRowOuterY, bottomRowInnerY, RulerRowType.Bottom);
        break;
      case RulerLengthType.Centimeter:
        this.drawCentimeterRow(bottomRowOuterY, bottomRowInnerY, RulerRowType.Bottom);
        break;
      default:
        this.drawInchRow(bottomRowOuterY, bottomRowInnerY, RulerRowType.Bottom);
        break;
    }
  }

  private drawInchRow(outerY: number, innerY: number, rowType: RulerRowType): void {
    const rowHeight = rowType === RulerRowType.Top ? innerY - outerY : outerY - innerY;
    const relevantLength: number = this.lengthType === RulerLengthType.Inch ? this.length : this.centimetersToInches(this.length);
    const leftPadding: number = this.canvasPadding;
    const segmentsPerInch: number = 16;
    const quarterBreakpointDivisible: number = 4;
    const eighthBreakpointDivisible: number = 2;
    const quarterMarkLengthRatio: number = 0.75;
    const eighthMarkLengthRatio: number = quarterMarkLengthRatio * 0.75;
    const sixteenthMarkLengthRatio: number = eighthMarkLengthRatio * 0.75;

    for (let i: number = 0; i < relevantLength; ++i) {
      const pastSegmentOffset: number = (this.getInchPixels() * i) + leftPadding;
      const innerSegmentWidth: number = this.getInchPixels() / segmentsPerInch;

      for (let segmentIndex: number = 0; segmentIndex < segmentsPerInch; ++segmentIndex) {
        const leftOffset: number = pastSegmentOffset + ((segmentIndex + 1) * innerSegmentWidth);
        let heightAddition: number = 0;
        const printNumber: string = String(i + 1);

        switch (true) {
          case (segmentIndex + 1) % segmentsPerInch === 0:
            heightAddition = Number(rowHeight);

            this.context.save();

            const fontArgs = this.context.font.split(' ');
            const newSize = `20px`;
            this.context.font = newSize + ' ' + fontArgs[fontArgs.length - 1];
            this.context.textAlign = 'left';

            this.context.beginPath();
            this.context.translate(leftOffset - this.context.measureText(printNumber).width - 2,
                                    rowType === RulerRowType.Top ?
                                    outerY + ((rowHeight * quarterMarkLengthRatio) + 10) :
                                    outerY - ((rowHeight * quarterMarkLengthRatio)  - 10));
            this.context.fillText(printNumber, 0, 0);
            this.context.closePath();

            this.context.restore();

          break;
          case (segmentIndex + 1) % quarterBreakpointDivisible === 0:
            heightAddition = rowHeight * quarterMarkLengthRatio;
          break;
          case (segmentIndex + 1) % eighthBreakpointDivisible === 0:
            heightAddition = rowHeight * eighthMarkLengthRatio;
          break;
          default:
            heightAddition = rowHeight * sixteenthMarkLengthRatio;
          break;
        }

        this.context.beginPath();
        this.context.moveTo(leftOffset, outerY);
        this.context.lineTo(leftOffset, rowType === RulerRowType.Top ? outerY + heightAddition : outerY - heightAddition);
        this.context.stroke();
        this.context.closePath();
      }
    }
  }

  private drawCentimeterRow(outerY: number, innerY: number, rowType: RulerRowType): void {
    const rowHeight = rowType === RulerRowType.Top ? innerY - outerY : outerY - innerY;
    const relevantLength = this.lengthType === RulerLengthType.Centimeter ? this.length : this.inchesToCentimeters(this.length);
    const leftPadding: number = this.canvasPadding;
    const segmentsPerCentimeter: number = 10;
    const halfwayBreakpointDivisible: number = 5;
    const halfwayMarkRatio: number = 0.50;
    const tenthMarkRatio: number = halfwayMarkRatio * 0.66;

    for (let i: number = 0; i < relevantLength; ++i) {
      const pastSegmentOffset: number = (this.getCentimeterPixels() * i) + leftPadding;
      const innerSegmentWidth: number = this.getCentimeterPixels() / segmentsPerCentimeter;

      for (let segmentIndex: number = 0; segmentIndex < segmentsPerCentimeter; ++segmentIndex) {
        const leftOffset: number = pastSegmentOffset + ((segmentIndex + 1) * innerSegmentWidth);
        let heightAddition: number = 0;
        const printNumber: string = String(i + 1);

        switch (true) {
          case (segmentIndex + 1) % segmentsPerCentimeter === 0:
            heightAddition = Number(rowHeight);

            this.context.save();

            const fontArgs = this.context.font.split(' ');
            const newSize = `17px`;
            this.context.font = newSize + ' ' + fontArgs[fontArgs.length - 1];
            this.context.textAlign = 'left';

            this.context.beginPath();
            this.context.translate(leftOffset - this.context.measureText(printNumber).width,
                                    rowType === RulerRowType.Top ?
                                    outerY + ((rowHeight * tenthMarkRatio) + 10) :
                                    outerY - ((rowHeight * tenthMarkRatio) + 1));
            this.context.fillText(printNumber, 0, 0);
            this.context.closePath();

            this.context.restore();
          break;
          case (segmentIndex + 1) % halfwayBreakpointDivisible === 0:
            heightAddition = rowHeight * halfwayMarkRatio;
          break;
          default:
            heightAddition = rowHeight * tenthMarkRatio;
          break;
        }

        this.context.beginPath();
        this.context.moveTo(leftOffset, outerY);
        this.context.lineTo(leftOffset, rowType === RulerRowType.Top ? outerY + heightAddition : outerY - heightAddition);
        this.context.stroke();
        this.context.closePath();
      }
    }
  }

  private inchesToCentimeters(inches: number): number {
    return inches * this.centimeterToInchRatio;
  }

  private centimetersToInches(centimeters: number): number {
    return centimeters / this.centimeterToInchRatio;
  }

  private getPixelToInchRatio(): number {
    return this.monitorPpi / this.defaultPixelsPerInch;
  }

  private getInchPixels(): number {
    return this.getPixelToInchRatio() * this.defaultPixelsPerInch;
  }

  private getCentimeterPixels(): number {
    return this.getPixelToInchRatio() * (this.getInchPixels() / this.centimeterToInchRatio);
  }

  private getInchesInPixels(inches: number): number {
    return (this.getInchPixels() *
            (this.scaleMeasurementByResolution ? window.devicePixelRatio : 1)) *
            inches + (this.canvasPadding * 2);
  }

  private getCentimetersInPixels(centimeters: number): number {
    return ((this.getInchPixels() / this.centimeterToInchRatio) *
            (this.scaleMeasurementByResolution ? window.devicePixelRatio : 1)) *
            centimeters + (this.canvasPadding * 2);
  }

  private getTotalWidth(): number {
    switch (this.lengthType) {
      case RulerLengthType.Inch:
        return this.getInchesInPixels(this.length);
      case RulerLengthType.Centimeter:
        return this.getCentimetersInPixels(this.length);
      case RulerLengthType.Feet:
        return this.getInchesInPixels(this.length) * 12;
    }
  }

  private getTotalHeight(): number {
    return ((this.rowHeight + this.canvasPadding) * 2) * (this.scaleMeasurementByResolution ? window.devicePixelRatio : 1);
  }

  public mouseDown(scope: RulerComponent, e: MouseEvent): void {
    scope.h_x = e.pageX;
    scope.h_y = e.pageY;
    e.preventDefault();
    e.stopPropagation();
    scope.dragging = true;

    const containerRect = scope.dragContainer.nativeElement.getBoundingClientRect();
    scope.baseOriginX = containerRect.left + (containerRect.width / 2);
    scope.baseOriginY = containerRect.top + (containerRect.height / 2);

    scope.originX = Number(scope.baseOriginX);
    scope.originY = Number(scope.baseOriginY);
  }

  @HostListener('document:mousemove', ['$event'])
  public mouseMove(e: MouseEvent): void {
    if (this.dragging) {
      const s_x = e.pageX;
      const s_y = e.pageY;
      if (s_x !== this.originX && s_y !== this.originY) {
        let s_rad = Math.atan2((s_y) - this.originY, (s_x) - this.originX);
        s_rad -= Math.atan2(this.h_y - this.originY, this.h_x - this.originX);
        s_rad += this.lastAngle;

        const degree = (s_rad * (360 / (2 * Math.PI)));
        this.rotateContainer.nativeElement.style.transform = `rotate(${degree}deg)`;
        this.rotateContainer.nativeElement.style.transformOrigin = '50% 50%';
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  public mouseUp(e: MouseEvent): void {
    if (this.dragging) {
      this.dragging = false;
      const s_x = e.pageX;
      const s_y = e.pageY;

      let s_rad = Math.atan2((s_y) - this.originY, (s_x) - this.originX);
      s_rad -= Math.atan2(this.h_y - this.originY, this.h_x - this.originX);
      s_rad += this.lastAngle;

      this.lastAngle = s_rad;
    }
  }
}
